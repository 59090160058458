import React, { useState, useEffect } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, TextInput, View, Dimensions } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { useReactiveVar } from '@apollo/client'

import { useUser } from '../hooks/user'
import { useCompany } from '../hooks/company'

import { reservationsVar } from '../utils/cache'

import Loading from '../components/Loading'
import Footer from '../components/Footer'
import Auth from '../components/Auth'
import Window from '../components/Window'

const ProfileScreen = () => {
    const navigation = useNavigation()
    const isFocused = useIsFocused()

    const { loading, error, refetch: refetchUser } = useUser()
    const { company, refetch: refetchCompany } = useCompany()
    const reservations = useReactiveVar(reservationsVar)

    useEffect(() => {
        if (isFocused) {
            refetchUser()
            refetchCompany()
        }
    }, [isFocused])

    const signOut = async () => {
        await AsyncStorage.clear()
        window.location.replace('/profile')
        refetchUser()
        refetchCompany()
    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Auth />
    }

    const toReservations = () => {
        reservationsVar(0)
        navigation.navigate('Reservations')
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={{ marginBottom: 24 }}>
        <Window style={{ flex: 1 }}>
            <View style={[styles.container, {marginTop: windowWidth > 768 ? 80 : 0}]}>
                <Text style={[styles.title, { marginBottom: 16 }]}>Профиль</Text>
                <TouchableOpacity onPress={() => navigation.navigate('Person')} style={styles.menuContainer}>
                    <Ionicons size={24} name='ios-person-outline' />
                    <Text style={styles.menuText}>Личная информация</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.navigate('Favorites')} style={styles.menuContainer}>
                    <Ionicons size={24} name='ios-heart-outline' />
                    <Text style={styles.menuText}>Избранные</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={toReservations} style={styles.menuContainer}>
                    <Ionicons size={24} name='ios-time-outline' />
                    <Text style={styles.menuText}>Мои брони {reservations ? `(${reservations})` : null}</Text>
                </TouchableOpacity>
                {
                    company ? (
                        <>
                            <TouchableOpacity onPress={() => navigation.navigate('MyObjects')} style={styles.menuContainer}>
                                <Ionicons size={24} name='business-outline' />
                                <Text style={styles.menuText}>Мои объекты</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('Requests')} style={styles.menuContainer}>
                                <Ionicons size={24} name='calendar-outline' />
                                <Text style={styles.menuText}>Заявки</Text>
                            </TouchableOpacity>
                        </>
                    ) : null
                }
                {/* <TouchableOpacity onPress={() => navigation.navigate('Points')} style={styles.menuContainer}>
                    <Ionicons size={23} name='ios-cash-outline' />
                    <Text style={styles.menuText}>Бонусы ({user.points})</Text>
                </TouchableOpacity> */}
                <TouchableOpacity onPress={signOut} style={styles.menuContainer}>
                    <Ionicons color='#dc3545' size={24} name='ios-exit-outline' />
                    <Text style={[styles.menuText, { color: '#dc3545' }]}>Выйти с профиля</Text>
                </TouchableOpacity>
            </View>
            <Footer />
        </Window>
        </ScrollView>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: 16,
        marginTop: 32,
    },
    title: {
        color: '#101828',
        fontSize: 30,
        fontWeight: '500',
        marginBottom: 8
    },
    text: {
        color: '#667085',
        marginBottom: 24
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 32
    },
    menuContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 16,
        borderBottomWidth: 1,
        borderColor: '#D0D5DD'
    },
    menuText: {
        marginLeft: 12,
        color: '#101828'
    }
})

export default ProfileScreen