import React, { useState, useEffect } from 'react'
import { ScrollView, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_MANY_SCHEDULE_FOR_ADMIN, FIND_MANY_REQUEST_FOR_ADMIN } from '../gqls'

import { imageUrl } from '../utils/const'

import { useCompany } from '../hooks/company'

import Loading from '../components/Loading'
import Footer from '../components/Footer'
import Window from '../components/Window'

dayjs.locale('ru')

LocaleConfig.locales['ru'] = {
    monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ],
    monthNamesShort: [
        'Янв',
        'Февр',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек'
    ],
    dayNames: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
}

LocaleConfig.defaultLocale = 'ru'

const statuses = {
    '0': 'Не оплачен',
    '1': 'Оплачен',
    '3': 'Отменен',
    '4': 'Отменен гостем'
}

const RequestsArchiveScreen = () => {
    const navigation = useNavigation()
    const now = dayjs()

    const [currentDate, setCurrentDate] = useState(null)
    const [date, setDate] = useState('')
    const [month, setMonth] = useState(parseInt(now.format('MM')))
    const [year, setYear] = useState(now.format('YYYY'))

    const [monthDates, setMonthDates] = useState([])

    const [selectedDate, setSelectedDate] = useState(null)
    const [picker, setPicker] = useState(false)

    const { company } = useCompany()

    const { data: findManyScheduleData, loading: findManyScheduleLoading } = useQuery(FIND_MANY_SCHEDULE_FOR_ADMIN, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                object: {
                    is: {
                        companyId: {
                            equals: company?.id
                        }
                    }
                }
            }
        },
        // pollInterval: 1000
    })

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_REQUEST_FOR_ADMIN, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                object: {
                    is: {
                        companyId: {
                            equals: company?.id
                        }
                    }
                },
                status: {
                    notIn: ['0', '1', '2']
                },
                
            },
            orderBy: {
                createdAt:'desc'
            },
        },
        // pollInterval: 1000
    })

    useEffect(() => {
        setCurrentDate(now.format('YYYY-MM-DD'))
    }, [])

    useEffect(() => {
        const dates = new Array(dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).daysInMonth()).fill(null).map((x, i) => dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).startOf('month').add(i, 'days').format('YYYY-MM-DD'))
        setMonthDates(dates)
    }, [month, year])

    if (!currentDate || findManyScheduleLoading || findManyRequestLoading) return <Loading />

    const { findManySchedule } = findManyScheduleData
    const { findManyRequest } = findManyRequestData

    const disableSchedule = monthDates.filter(object => now.format('YYYY-MM-DD') === object || now.isBefore(object)).reduce((acc, current) => {
        acc[current] = {
            disabled: true,
            disableTouchEvent: true
        }
        const week = findManySchedule.filter(schedule => schedule.week.some(object => object.toLowerCase() === dayjs(current).format('dddd')))
        if (week.length !== 0) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: true,
                marked: true,
                dotColor: '#66ff00'
            }
        }
        const days = findManySchedule.filter(schedule => schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY')))
        if (days.length !== 0) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: true,
                marked: true,
                dotColor: '#66ff00'
            }
        }
        const requests = findManyRequest.filter(request => request.reservedAt === current && (request.status !== '3' || request.status !== '4'))
        if ((requests.length > 0 && days.length > 0 && requests.length === days.length) || (requests.length > 0 && week.length > 0 && days.length === 0 && requests.length === week.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: false,
                marked: true,
                dotColor: 'red'
            }
        }
        if ((requests.length > 0 && days.length > 0 && requests.length !== days.length) || (requests.length > 0 && week.length > 0 && days.length === 0 && requests.length !== week.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: false,
                marked: true,
                dotColor: 'yellow'
            }
        }
        if (requests.length > 0 && requests.some(request => request.schedule?.fullday)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: false,
                marked: true,
                dotColor: 'red'
            }
        }
        const closes = findManySchedule.filter(schedule => schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')) && schedule.status == true)
        if ((closes.length > 0 && days.length > 0 && closes.length >= days.length) || (closes.length > 0 && week.length > 0 && days.length === 0 && closes.length >= week.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: true,
                marked: true,
                dotColor: 'gray'
            }
        }
        if ((closes.length > 0 && days.length > 0 && requests.length > 0 && days.length > closes.length) || (closes.length > 0 && week.length > 0 && requests.length > 0 && days.length === 0 && week.length > closes.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: false,
                marked: true,
                dotColor: 'yellow'
            }
        }
        if ((closes.length > 0 && days.length > 0 && requests.length === 0 && days.length > closes.length) || (closes.length > 0 && week.length > 0 && requests.length === 0 && days.length === 0 && week.length > closes.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: true,
                marked: true,
                dotColor: 'gray'
            }
        }
        if ((requests.length > 0 && days.length > 0 && closes.length > 0 && (requests.length + closes.length) === days.length) || (requests.length > 0 && week.length > 0 && closes.length > 0 && days.length === 0 && (requests.length + closes.length) === week.length)) {
            acc[current] = {
                disabled: false,
                disableTouchEvent: false,
                marked: true,
                dotColor: 'red'
            }
        }
        return acc
    }, {})

    const requests = findManyRequest.filter(request => !selectedDate || request.reservedAt === selectedDate)

    return (
        <>
            <ScrollView stickyHeaderIndices={[0]} style={styles.container}>
                <Window style={{ backgroundColor: '#fff' }}>
                    <View style={{ margin: 16 }}>
                        <TouchableOpacity onPress={() => navigation.navigate('Requests')} style={styles.easementButton}>
                            <Text style={styles.easementButtonText}>К заявкам</Text>
                        </TouchableOpacity>
                    </View>
                </Window>
                <Window>
                    <View style={{ marginBottom: 24, marginHorizontal: 16 }}>
                        {
                            requests.map(object => (
                                <View style={styles.request} key={object.id}>
                                    <View style={styles.header}>
                                        <View style={styles.group}>
                                            <Text style={styles.requestTitle}>Дата и время брони</Text>
                                            <Text>{dayjs(object.reservedAt).format('DD.MM.YY')} {object.schedule != null ? object.schedule.startAt + '-' + object.schedule.endAt : ''}</Text>
                                        </View>
                                        <View style={styles.statusContainer}>
                                            <Text style={styles.status}>{statuses[object.status]}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.group}>
                                        <Text style={styles.requestTitle}>Имя пользователя</Text>
                                        <Text>{object.name}</Text>
                                    </View>
                                    <TouchableOpacity onPress={() => navigation.navigate('MyObject', { id: object.object.id })} style={styles.objectContainer}>
                                        <Image source={object.object.images_720.length > 0 ? { uri: imageUrl(object.object.images_720[0]) } : { uri: imageUrl(object.object.images[0]) }} style={styles.image} />
                                        <Text style={styles.objectName}>{object.object.name}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => navigation.navigate('Request', { id: object.id })} style={styles.button}>
                                        <Text style={styles.buttonText}>Подробнее</Text>
                                    </TouchableOpacity>
                                </View>
                            ))
                        }
                    </View>
                </Window>
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    calendarContainer: {
        marginBottom: 12
    },
    request: {
        marginBottom: 16
    },
    title: {
        color: '#101828',
        fontSize: 17,
        fontWeight: '500',
        marginBottom: 12,
        marginHorizontal: 16
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 8
    },
    headerTitle: {
        color: '#101828'
    },
    status: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 16,
    },
    statusContainer: {
        backgroundColor: '#BCC8B2',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4
    },
    requestTitle: {
        fontSize: 12,
        marginBottom: 4,
        color: '#667085'
    },
    group: {
        marginBottom: 8
    },
    objectContainer: {
        marginVertical: 12,
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        height: 56,
        width: 56,
        borderRadius: 12,
        backgroundColor: '#D0D5DD',
        marginRight: 24
    },
    objectName: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 16
    },
    button: {
        height: 36,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E6B762',
        borderRadius: 10
    },
    buttonText: {
        color: '#101828'
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        flexDirection: 'row'
    },
    easementButton: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: 36,
        borderWidth: 1,
        borderColor: '#101828',
        borderRadius: 8,
        marginTop: 12
    },
    easementButtonText: {
        color: '#101828',
        marginLeft: 4
    },
})

export default RequestsArchiveScreen