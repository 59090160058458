import React from 'react'
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native'

const webMap2gis = (location) => `
    <!DOCTYPE html>
    <html>
        <head>
            <title>Создание карты</title>
            <script src="https://maps.api.2gis.ru/2.0/loader.js"></script>
            <style>
                html,body {
                    margin: 0;
                    padding: 0;
                }
            </style>
        </head>
        <body>
            <div id="map" style="width: 100%; height: 100vh;"></div>
            <script>
                DG.then(function() {
                    var map = DG.map('map', {
                        center: [${location}],
                        zoom: 15,
                        fullscreenControl: false,
                        preferCanvas: true,
                        poi: false,
                        scrollWheelZoom: false
                    })
                    DG.marker([${location}]).addTo(map);
                });
            </script>
        </body>
    </html>
`

const MapView = ({ location }) => {
    const { width } = useWindowDimensions()

    const squareSize = width > 1024 ? 480 : width - 32

    if (Platform.OS === 'web') {
        return (
            <View style={{ height: squareSize, borderRadius: 20, overflow: 'hidden' }}>
                <iframe
                    style={styles.container}
                    srcDoc={webMap2gis(`${location.latitude}, ${location.longitude}`)}
                />
            </View>
        )
    }

    return null
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
})

export default MapView