import React from 'react'
import {
    View,
    StyleSheet,
    Text
} from 'react-native'

const Error = ({ style }) => {
    return (
        <View style={[styles.container, style]}>
            <Text>Упссс... Ошибка... Повторите позже...</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center'
    },
})

export default Error