import React, { useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, TextInput, Platform } from 'react-native'
import { useMutation } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import IMask from 'imask'

import Loading from '../components/Loading'
import Error from '../components/Error'

import { useUser } from '../hooks/user'

import { UPDATE_ONE_USER } from '../gqls'

const phoneMask = IMask.createMask({
    mask: '70000000000'
})

const PointsScreen = () => {
    const navigation = useNavigation()

    const [phone, setPhone] = useState('')
    const [points, setPoints] = useState('')

    const { user, loading: userLoading, error: userError } = useUser()

    const [updateOneUserMutation, { loading: updateOneUserLoading, error: updateOneUserError }] = useMutation(UPDATE_ONE_USER)

    if (updateOneUserLoading || userLoading) {
        return <Loading />
    }

    if (updateOneUserError || userError) {
        return <Error />
    }

    const onChangePoints = (value) => {
        const pointsMask = IMask.createMask({
            mask: Number,
            max: user.points,
            min: 1
        })
        const maskedValue = pointsMask.resolve(value)
        setPoints(maskedValue)
    }

    const onChangePhone = (value) => {
        const maskedValue = phoneMask.resolve(value)
        setPhone(maskedValue)
    }

    const onSubmit = async () => {
        if (points > user.points) return
        await updateOneUserMutation({
            variables: {
                where: {
                    id: user.id
                },
                data: {
                    points: {
                        decrement: parseInt(points)
                    }
                }
            }
        })
        await updateOneUserMutation({
            variables: {
                where: {
                    phone
                },
                data: {
                    points: {
                        increment: parseInt(points)
                    }
                }
            }
        })
        if (navigation.canGoBack()) {
            navigation.goBack()
        } else {
            window.history.back()
        }
    }

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.title}>У вас есть {user.points} баллов</Text>
            <TextInput value={points} onChangeText={onChangePoints} style={styles.input} placeholder='Количество бонусов' placeholderTextColor='#667085' />
            <TextInput value={phone} onChangeText={onChangePhone} style={styles.input} placeholder='Отправить по номеру телефона' placeholderTextColor='#667085' />
            <TouchableOpacity onPress={onSubmit} style={styles.button}>
                <Text style={styles.buttonText}>Отправить</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: 16,
        paddingTop: 32,
    },
    title: {
        fontSize: 20,
        fontWeight: '500',
        marginBottom: 16
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 16,
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#101828',
        marginTop: 24
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    }
})

export default PointsScreen