import React from 'react'
import { ScrollView, StyleSheet, Text, View, Dimensions } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

import { FIND_UNIQUE_OBJECT } from '../gqls'

const ReviewsScreen = ({ route }) => {
    const { id } = route.params

    const { data: findUniqueObjectData, loading: findUniqueObjectLoading, error: findUniqueObjectError } = useQuery(FIND_UNIQUE_OBJECT, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        }
    })

    if (findUniqueObjectLoading) return <Loading />
    if (findUniqueObjectError) return <Error />

    const { findUniqueObject } = findUniqueObjectData

    const getRating = (requests) => {
        if (requests.length === 0) return (0).toFixed(2)
        const rating = requests.map(object => object.rating).reduce((a = 0, b = 0) => a + b, 0) / requests.length
        if (rating === 0) return (0).toFixed(2)
        return rating.toFixed(2)
    }

    const getReviewCount = (requests) => {
        if (requests.length === 0) return 0
        return requests.filter(object => object.rating && object.rating !== 0).length
    }

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
            <Window style={{ paddingHorizontal: windowWidth > 768 ? 24 : 16 }}>
                <View style={styles.reviewsTop}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                        <path d="M15.0522 3.49598C14.8582 3.09103 14.4491 2.83334 14 2.83334C13.551 2.83334 13.1419 3.09103 12.9479 3.49598L9.86465 9.9323L2.76482 10.8646C2.31848 10.9232 1.94568 11.2333 1.80688 11.6616C1.66808 12.0898 1.78804 12.5597 2.11513 12.869L7.3061 17.7775L6.00314 24.7868C5.921 25.2287 6.1 25.6785 6.46332 25.9431C6.82663 26.2077 7.30963 26.24 7.70501 26.0263L14 22.6229L20.2951 26.0263C20.6904 26.24 21.1734 26.2077 21.5368 25.9431C21.9001 25.6785 22.0791 25.2287 21.9969 24.7868L20.694 17.7775L25.8849 12.869C26.212 12.5597 26.332 12.0898 26.1932 11.6616C26.0544 11.2333 25.6816 10.9232 25.2353 10.8646L18.1354 9.9323L15.0522 3.49598Z" fill="url(#paint0_linear_359_8159)"/>
                        <defs>
                        <linearGradient id="paint0_linear_359_8159" x1="19.7465" y1="23.2173" x2="8.42318" y2="7.94767" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF5E3F"/>
                        <stop offset="0.5" stop-color="#FF8B3F"/>
                        <stop offset="1" stop-color="#FFB93F"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <Text style={styles.reviewsTopText}>
                        {getRating(findUniqueObject.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3))}  
                    </Text>
                </View>
                <Text style={styles.reviewsCount}>
                    {getReviewCount(findUniqueObject.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3))} отзывов
                </Text>
                {
                    findUniqueObject.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3).map((object, index) => (
                        <View key={index} style={styles.reviewContainer}>
                            <View style={styles.reviewHeaderContainer}>
                                <View style={{flexDirection: 'row', gap: 6}}>
                                    <Text style={styles.author}>{object.name}</Text>
                                    <Text style={styles.date}>{dayjs(object.reservedAt).format('DD MMMM YYYY')}</Text>
                                </View>
                                {/* <View style={styles.starsContainer}>
                                    {
                                        [1, 2, 3, 4, 5].map((star, index) => (
                                            <View key={index}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 29" fill="none">
                                                    <path d="M15.0522 3.49598C14.8582 3.09103 14.4491 2.83334 14 2.83334C13.551 2.83334 13.1419 3.09103 12.9479 3.49598L9.86465 9.9323L2.76482 10.8646C2.31848 10.9232 1.94568 11.2333 1.80688 11.6616C1.66808 12.0898 1.78804 12.5597 2.11513 12.869L7.3061 17.7775L6.00314 24.7868C5.921 25.2287 6.1 25.6785 6.46332 25.9431C6.82663 26.2077 7.30963 26.24 7.70501 26.0263L14 22.6229L20.2951 26.0263C20.6904 26.24 21.1734 26.2077 21.5368 25.9431C21.9001 25.6785 22.0791 25.2287 21.9969 24.7868L20.694 17.7775L25.8849 12.869C26.212 12.5597 26.332 12.0898 26.1932 11.6616C26.0544 11.2333 25.6816 10.9232 25.2353 10.8646L18.1354 9.9323L15.0522 3.49598Z" fill="url(#paint0_linear_359_8159)"/>
                                                    <defs>
                                                    <linearGradient id="paint0_linear_359_8159" x1="19.7465" y1="23.2173" x2="8.42318" y2="7.94767" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF5E3F"/>
                                                    <stop offset="0.5" stop-color="#FF8B3F"/>
                                                    <stop offset="1" stop-color="#FFB93F"/>
                                                    </linearGradient>
                                                    </defs>
                                                </svg>
                                            </View>
                                        ))
                                    }
                                </View> */}
                            </View>
                            <Text style={styles.text}>{object.review}</Text>
                        </View>
                    ))
                }
            </Window>
        </ScrollView>
    )
}


const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    reviewHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    starsContainer: {
        flexDirection: 'row'
    },
    reviewContainer: {
        marginBottom: 32
    },
    author: {
        color: '#101828',
        fontWeight: '600',
        lineHeight: '150%'
    },
    date: {
        color: '#6B7280',
        lineHeight: '150%'
    },
    text: {
        color: '#111928',
        lineHeight: '150%',
        fontSize: 16
    },
    reviewsTop: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
        marginBottom: 24
    },
    reviewsTopText: {
        fontSize: 30,
        lineHeight: '150%',
        fontWeight: 700,
    },
    reviewsCount: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '150%',
        marginBottom: 24
    }
})

export default ReviewsScreen
