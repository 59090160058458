import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Footer from '../components/Footer'
import Window from '../components/Window'

const text = `Общество с ограниченной ответственностью "Локальные сервисы"
ИНН/КПП: 1400019015/140001001
ОГРН 1231400001964
Юридический адрес: 677000, Республика Саха (Якутия), г.Якутск, 203 микрорайон, дом 28, помещение 216.
e-mail: domikiykt@yandex.ru
Телефон: 8-914-222-11-79

Банковские реквизиты:
Банк: ПАО Сбербанк
БИК: 049805609
Расчетный счет: 40702810576000030655
Корр. счет: 30101810400000000609`

const RequisitesScreen = () => {
    return (
        <Window style={{ flex: 1 }}>
            <View style={styles.container}>
                <Text style={styles.text}>{text}</Text>
            </View>
            <Footer />
        </Window>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16
    },
    text: {
        color: '#000',
        fontSize: 16
    },
})

export default RequisitesScreen