import { ApolloProvider } from '@apollo/client'

import client from './src/components/Apollo'
import Router from './src/components/Router'
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <ApolloProvider client={client}>
            <Router />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark"
                transition={Slide}
            />
        </ApolloProvider>
    )
}

export default App