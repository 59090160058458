import React, { useState } from 'react'
import { ScrollView, ImageBackground, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions, Dimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { useQuery } from '@apollo/client'
import FsLightbox from 'fslightbox-react'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { imageUrl } from '../utils/const'

import { FIND_UNIQUE_POST } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const FeedScreen = ({ route }) => {
    const { id } = route.params
    const { width } = useWindowDimensions()
    const navigation = useNavigation()
    const [toggler, setToggler] = useState(false)

    const squareSize = width > 1024 ? 480 : width - 32

    const { data: findUniquePostData, loading: findUniquePostLoading, error: findUniquePostError } = useQuery(FIND_UNIQUE_POST, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        }
    })

    if (findUniquePostLoading) return <Loading />
    if (findUniquePostError) return <Error />

    const { findUniquePost } = findUniquePostData

    const openImage = () => {
        setToggler(prev => !prev)
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginBottom: 24, marginTop: windowWidth > 768 ? 80 : 0 }}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <TouchableOpacity activeOpacity={1} onPress={openImage}>
                        <ImageBackground style={[styles.objectImage, { height: squareSize }]} source={{ uri: imageUrl(findUniquePost.image) }}>
                            <View style={styles.head}>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (navigation.canGoBack()) {
                                            navigation.goBack()
                                        } else {
                                            window.history.back()
                                        }
                                    }}
                                    style={styles.back}
                                >
                                    <Ionicons name='ios-chevron-back-outline' size={24} color='#475467' />
                                </TouchableOpacity>
                            </View>
                        </ImageBackground>
                    </TouchableOpacity>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{findUniquePost.user}</Text>
                        <Text style={styles.date}>{dayjs.unix(findUniquePost.date).format('DD MMMM YYYY')}</Text>
                    </View>
                    <Text style={styles.description}>{findUniquePost.text}</Text>
                </Window>
            </ScrollView>
            <FsLightbox
                toggler={toggler}
                sources={[imageUrl(findUniquePost.image)]}
                sourceIndex={0}
            />
        </>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between',
        marginTop: 12
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    back: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: '#F2F4F7',
        margin: 16
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 8
    },
    title: {
        fontSize: 20,
        color: '#101828',
        fontWeight: '500',
    },
    date: {
        fontSize: 16,
        color: '#667085'
    },
    description: {
        color: '#101828'
    }
})

export default FeedScreen