import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

const webMap2gis = (objects) => `
    <!DOCTYPE html>
    <html>
        <head>
            <title>Создание карты</title>
            <script src="https://maps.api.2gis.ru/2.0/loader.js"></script>
            <style>
                html,body {
                    margin: 0;
                    padding: 0;
                }
                .leaflet-div-icon {
                    background: transparent !important;
                    border: none !important;
                }
            </style>
        </head>
        <body>
            <div id="map" style="width: 100%; height: 100vh;"></div>
            <script>
                DG.then(function() {
                    var map = DG.map('map', {
                        center: [62.035454, 129.675476],
                        zoom: 10,
                        fullscreenControl: false,
                        zoomControl: false,
                        preferCanvas: true,
                        poi: false,
                    })
                    var markers = DG.featureGroup()
                    var objects = ${objects}
                    for (let object of objects) {
                        var myDivIcon = DG.divIcon({
                            iconSize: [34, 34],
                            html: '<img style="background-color:white; border-radius: 17px; width: 34px; height: 34px; overflow: hidden;" src="'+ object.image + '" />'
                        })
                        DG.marker(object.location, {
                            icon: myDivIcon
                        }).on('click', function() {
                            parent.postMessage(JSON.stringify({ id: object.id, type: 'navigate' }), '*')
                        }).addTo(markers)
                    }
                    markers.addTo(map)
                });
            </script>
        </body>
    </html>
`

const Map = ({ markers }) => {
    const data = markers.map(object => `{ id: "${object.id}",location:[${object.location[1]}, ${object.location[0]}], image: "${object.image}" }`).join(',')
    if (Platform.OS === 'web') {
        return (
            <View style={{ flex: 1, height: '100%', width: '100%' }}>
                <iframe
                    style={styles.container}
                    srcDoc={webMap2gis(`[${data}]`)}
                />
            </View>
        )
    }
    return null
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
})

export default Map