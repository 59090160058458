import React, { useEffect, useState, useRef } from 'react'
import { ScrollView, Image, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions, TextInput } from 'react-native'

import { useNavigation, useIsFocused } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_UNIQUE_OBJECT, UPDATE_ONE_OBJECT, FIND_MANY_SCHEDULE_FOR_ADMIN, UPDATE_ONE_SCHEDULE, FIND_MANY_REQUEST_FOR_ADMIN } from '../gqls'

import { useUser } from '../hooks/user'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'
import { toast } from 'react-toastify'

import { useCompany } from '../hooks/company'
dayjs.locale('ru')

const statuses = {
    '0': 'Ждет подтверждения',
    '1': 'Оплачен',
    '3': 'Отменен',
    '4': 'Отменен'
}

const MyObjectScreen = ({ route }) => {
    const { id } = route.params
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const { user, refetch } = useUser()

    const [selectTab, setSelectTab] = useState(0)
    const [note, setNote] = useState('')

    useEffect(() => {
        if (isFocused) {
            refetch()
        }
    }, [isFocused])


    const [findUniqueObjectQuery, { loading: findUniqueObjectLoading, error: findUniqueObjectError }] = useLazyQuery(FIND_UNIQUE_OBJECT, {
        fetchPolicy: 'network-only',
    })

    const [findManyScheduleQuery, { data: findManyScheduleData, loading: findManyScheduleLoading, error: findManyScheduleError }] = useLazyQuery(FIND_MANY_SCHEDULE_FOR_ADMIN, {
        fetchPolicy: 'network-only'
    })

    const [findManyRequestQuery, { data: findManyRequestData, loading: findManyRequestLoading, error: findManyRequestError }] = useLazyQuery(FIND_MANY_REQUEST_FOR_ADMIN, {
        fetchPolicy: 'network-only',
    })

    useCompany({
        onCompleted: async ({ findCurrentCompany }) => {
            if (findCurrentCompany != null) {
                var checkCurrentCompany = false;
                findCurrentCompany.objects.forEach(object => {
                    if (object.id == id)
                        checkCurrentCompany = true
                });
                if (checkCurrentCompany) {
                    await findUniqueObjectQuery({
                        variables: {
                            where: {
                                id
                            }
                        },
                        onCompleted: ({ findUniqueObject }) => {
                            setNote(findUniqueObject.note)
                            navigation.setOptions({
                                title: findUniqueObject.name
                            })
                        }
                    })
                } else
                    window.location.replace('/myObjects');
                await findManyScheduleQuery({
                    variables: {
                        orderBy: {
                            createdAt: 'desc'
                        },
                        where: {
                            objectId: {
                                equals: id
                            },
                            object: {
                                is: {
                                    companyId: {
                                        equals: findCurrentCompany.id
                                    }
                                }
                            },
                        }
                    },
                })
                await findManyRequestQuery({
                    variables: {
                        orderBy: {
                            createdAt: 'desc'
                        },
                        where: {
                            objectId: {
                                equals: id
                            },
                            object: {
                                is: {
                                    companyId: {
                                        equals: findCurrentCompany.id
                                    }
                                }
                            },
                        }
                    }
                })
            } else
                window.location.replace('/profile');
        },
        onError: () => {
            window.location.replace('/profile');
        }
    })

    const [updateOneScheduleMutation] = useMutation(UPDATE_ONE_SCHEDULE)
    const [updateOneObjectMutation] = useMutation(UPDATE_ONE_OBJECT)

    if (findUniqueObjectLoading || findManyScheduleLoading || findManyRequestLoading || !findManyScheduleData || !findManyRequestData) return <Loading />
    if (findUniqueObjectError || findManyScheduleError || findManyRequestError) return <Error />

    const { findManySchedule } = findManyScheduleData
    const { findManyRequest } = findManyRequestData

    const closedSchedules = findManySchedule.filter(object => object.closes.length > 0 && object.status == true).map(object => object.closes.map(close => ({
        id: object.id,
        name: object.name,
        close: close
    }))).flat(Infinity)

    const saveStatus = async () => {
        await updateOneObjectMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    note: {
                        set: note
                    }
                }
            },
            onCompleted: async () => {
                toast('Информация сохранена')
            }
        })
    }

    const deleteClose = async (object) => {
        const schedule = findManySchedule.find(schedule => schedule.id === object.id)
        const closes = schedule.closes.filter(close => close !== object.close)

        await updateOneScheduleMutation({
            variables: {
                where: {
                    id: object.id
                },
                data: {
                    closes
                }
            }
        })
    }

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <View style={styles.tabsContainer}>
                        <TouchableOpacity onPress={() => setSelectTab(0)} style={selectTab === 0 ? styles.selectTabContainer : styles.tabContainer}>
                            <Text style={selectTab === 0 ? styles.selectTabText : styles.tabText}>Заявки</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setSelectTab(1)} style={selectTab === 1 ? styles.selectTabContainer : styles.tabContainer}>
                            <Text style={selectTab === 1 ? styles.selectTabText : styles.tabText}>Закрытые брони</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setSelectTab(2)} style={selectTab === 2 ? styles.selectTabContainer : styles.tabContainer}>
                            <Text style={selectTab === 2 ? styles.selectTabText : styles.tabText}>Информация</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setSelectTab(3)} style={selectTab === 3 ? styles.selectTabContainer : styles.tabContainer}>
                            <Text style={selectTab === 3 ? styles.selectTabText : styles.tabText}>Архив заявок</Text>
                        </TouchableOpacity>
                    </View>
                    {
                        selectTab === 0 ? (
                            <View style={styles.header}>
                                <View style={[styles.column, styles.line]}>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Дата оформление</Text>
                                    </View>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Дата брони</Text>
                                    </View>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Время брони</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Информация</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Статус</Text>
                                    </View>
                                </View>
                                {
                                    findManyRequest.filter(object => object.status == 0 || object.status == 1 || object.status == 2).map((object, index) => (
                                        <TouchableOpacity key={index} onPress={() => navigation.navigate('Request', { id: object.id })} style={[styles.column, { paddingVertical: 12 }]}>
                                            <View style={{ flex: 1, paddingRight: 12 }}>
                                                <Text>{dayjs(object.createdAt).format('DD.MM.YYYY')}</Text>
                                            </View>
                                            <View style={{ flex: 1, paddingRight: 12 }}>
                                                <Text>{dayjs(object.reservedAt).format('DD.MM.YYYY')}</Text>
                                            </View>
                                            {
                                                object.schedule != null ? (
                                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                                        <Text>{object.schedule.startAt} - {object.schedule.endAt}</Text>
                                                    </View>
                                                ) : null
                                            }
                                            <View style={{ flex: 1 }}>
                                                <Text>{object.name}</Text>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Text>{statuses[object.status]}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    ))
                                }
                                {
                                    findManyRequest.filter(object => object.status == 0 || object.status == 1 || object.status == 2).length === 0 ? (
                                        <View style={{ marginVertical: 12 }}>
                                            <Text>Нет записей</Text>
                                        </View>
                                    ) : null
                                }
                            </View>
                        ) : null
                    }
                    {
                        selectTab === 1 ? (
                            <View style={styles.header}>
                                <View style={[styles.column, styles.line, { paddingRight: 30 }]}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Название графика</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Дата</Text>
                                    </View>
                                </View>
                                {
                                    closedSchedules.sort((a, b) => dayjs(b.close, 'DD.MM.YYYY').unix() - dayjs(a.close, 'DD.MM.YYYY').unix()).map((object, index) => (
                                        <View key={index} style={[styles.column, { paddingVertical: 12 }]}>
                                            <View style={{ flex: 1 }}>
                                                <Text>{object.name}</Text>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Text>{object.close}</Text>
                                            </View>
                                            <TouchableOpacity onPress={() => deleteClose(object)} style={styles.deleteSchedule}>
                                                <Ionicons size={14} name='close-outline' />
                                            </TouchableOpacity>
                                        </View>
                                    ))
                                }
                                {
                                    closedSchedules.length === 0 ? (
                                        <View style={{ marginVertical: 12 }}>
                                            <Text>Нет записей</Text>
                                        </View>
                                    ) : null
                                }
                            </View>
                        ) : null
                    }
                    {
                        selectTab === 2 ? (
                            <View style={{ marginTop: 16 }}>
                                <Text style={styles.title}>Заметка обьекта</Text>
                                <View style={styles.input}>
                                    <TextInput value={note} onChangeText={value => setNote(value)} style={{ flex: 1 }} multiline placeholder='Введите текст' placeholderTextColor='#667085' />
                                </View>
                            </View>
                        ) : null
                    }
                    {
                        selectTab === 3 ? (
                            <View style={styles.header}>
                                <View style={[styles.column, styles.line]}>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Дата оформление</Text>
                                    </View>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Дата брони</Text>
                                    </View>
                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                        <Text style={styles.title}>Время брони</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Информация</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.title}>Статус</Text>
                                    </View>
                                </View>
                                {
                                    findManyRequest.filter(object => object.status == 3 || object.status == 4).map((object, index) => (
                                        <TouchableOpacity key={index} onPress={() => navigation.navigate('Request', { id: object.id })} style={[styles.column, { paddingVertical: 12 }]}>
                                            <View style={{ flex: 1, paddingRight: 12 }}>
                                                <Text>{dayjs(object.createdAt).format('DD.MM.YYYY')}</Text>
                                            </View>
                                            <View style={{ flex: 1, paddingRight: 12 }}>
                                                <Text>{dayjs(object.reservedAt).format('DD.MM.YYYY')}</Text>
                                            </View>
                                            {
                                                object.schedule != null ? (
                                                    <View style={{ flex: 1, paddingRight: 12 }}>
                                                        <Text>{object.schedule.startAt} - {object.schedule.endAt}</Text>
                                                    </View>
                                                ) : null
                                            }
                                            <View style={{ flex: 1 }}>
                                                <Text>{object.name}</Text>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Text>{statuses[object.status]}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    ))
                                }
                                {
                                    findManyRequest.filter(object => object.status == 3 || object.status == 4).length === 0 ? (
                                        <View style={{ marginVertical: 12 }}>
                                            <Text>Нет записей</Text>
                                        </View>
                                    ) : null
                                }
                            </View>
                        ) : null
                    }
                </Window>
            </ScrollView>
            <Window>
                {/* 
                {
                    selectTab === 0 ? (
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={() => { }} style={[styles.button, { backgroundColor: '#101828' }]}>
                                <Text style={styles.buttonText}>Фильтр</Text>
                            </TouchableOpacity>
                        </View>
                    ) : null
                } 
                */}
                {
                    selectTab === 1 ? (
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={() => navigation.navigate('Close', { id })} style={styles.button}>
                                <Text style={styles.buttonText}>Закрыть бронь</Text>
                            </TouchableOpacity>
                        </View>
                    ) : null
                }
                {
                    selectTab === 2 ? (
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={saveStatus} style={[styles.button, { backgroundColor: '#BCC8B2' }]}>
                                <Text style={styles.buttonText}>Сохранить</Text>
                            </TouchableOpacity>
                        </View>
                    ) : null
                }
            </Window>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    tabsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
    },
    tabContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4
    },
    tabText: {
        color: '#101828',
        textAlign: 'center',
        fontSize: 12
    },
    selectTabContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        borderRadius: 4,
        backgroundColor: '#101828'
    },
    selectTabText: {
        color: '#FCFCFD',
        textAlign: 'center',
        fontSize: 12
    },
    input: {
        height: 100,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        padding: 10,
        marginBottom: 16,
    },
    title: {
        marginBottom: 12,
        fontWeight: '500'
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    column: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        marginVertical: 16
    },
    line: {
        borderBottomWidth: 1,
        borderColor: '#D0D5DD',
    },
    deleteSchedule: {
        width: 30,
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default MyObjectScreen