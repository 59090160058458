import React from 'react'
import { ScrollView, StyleSheet, Text, View, Image, Dimensions } from 'react-native'
import { useQuery, useLazyQuery } from '@apollo/client'

import { imageUrl } from '../utils/const'

import { FIND_UNIQUE_OBJECT, FIND_MANY_ICON } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const exceptionComforts = [
    'breakfast',
    'pet',
    'bathhouse',
    'garage',
    'transfer',
    'playground',
    'pool',
    'conditioner',
    'wifi',
    'bathroom',
    'kitchen',
    'fridge',
    'dishes',
    'river',
    'heat'
]

const ComfortsScreen = ({ route }) => {
    const { id } = route.params

    const [findManyIconQuery, { data: findManyIconData, loading: findManyIconLoading, error: findManyIconError }] = useLazyQuery(FIND_MANY_ICON, {
        fetchPolicy: 'network-only',
    })

    useQuery(FIND_UNIQUE_OBJECT, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        },
        onCompleted: async ({ findUniqueObject }) => {
            const comforts = findUniqueObject.comforts.filter(object => !exceptionComforts.includes(object))
            await findManyIconQuery({
                variables: {
                    where: {
                        name: {
                            in: comforts
                        }
                    }
                }
            })
        }
    })

    if (findManyIconLoading || !findManyIconData) return <Loading />
    if (findManyIconError) return <Error />

    const { findManyIcon } = findManyIconData

    const comfortsGroup = findManyIcon.reduce((acc, current) => {
        if (acc[current.category]) {
            acc[current.category] = [
                ...acc[current.category],
                current
            ]
        } else {
            acc[current.category] = [
                current
            ]
        }
        return acc
    }, {})

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
            <Window style={{ paddingHorizontal: windowWidth > 768 ? 32 : 20 }}>
                <Text style={styles.title}>Удобства</Text>
                <View style={styles.comfortsWrap}>
                    {
                        Object.entries(comfortsGroup)?.slice(0, 2).map(([key, value], index) => (
                            <View key={index} style={{ width: '100%' }}>
                                <Text style={styles.preTitle}>{key}</Text>
                                <View style={{flexWrap: 'wrap', flexDirection: 'row'}}>
                                    {
                                        value.map((object, index) => (
                                            <View key={index} style={styles.group}>
                                                <Image resizeMode='contain' style={{ width: 24, height: 24 }} source={{ uri: imageUrl(object.image) }} />
                                                <Text style={styles.text}>{object.name}</Text>
                                            </View>
                                        ))
                                    }
                                </View>
                            </View>
                        ))
                    }
                    {
                        Object.entries(comfortsGroup)?.slice(2).map(([key, value], index) => (
                            <View key={index} style={{ marginTop: 32, marginBottom: 22, width: '100%' }}>
                                <Text style={styles.preTitle}>{key}</Text>
                                {
                                    value.map((object, index) => (
                                        <View
                                            key={index}
                                            style={[styles.groupSelf, {marginTop: 10}]}
                                        >
                                            <Text style={styles.textSelf}>{object.name}</Text>
                                        </View>
                                    ))
                                }
                            </View>
                        ))
                    }
                </View>
            </Window>
        </ScrollView>
    )
}

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    group: {
        flexDirection: 'row',
        paddingVertical: 24,
        alignItems: 'flex-start',
        borderBottomWidth: windowWidth > 768 ? 0 : 1,
        borderColor: '#E5E7EB',
        paddingRight: windowWidth > 768 ? 48 : 0,
        width: windowWidth > 768 ? '50%' : '100%'
    },
    groupSelf: {
        flexDirection: 'row',
        backgroundColor: '#F3F4F6',
        borderRadius: 8,
        width: '100%',
        marginTop: windowWidth > 768 ? 0 : 24,
    },
    title: {
        color: '#0F1721',
        fontSize: 24,
        fontWeight: '700',
        marginBottom: 24,
        lineHeight: '150%',
    },
    preTitle: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '150%',
        marginBottom: windowWidth > 768 ? 24 : 0,
    },
    text: {
        color: '#222',
        marginLeft: 16,
        fontSize: 16,
        lineHeight: '125%',
        marginTop: 2
    },
    textSelf: {
        fontSize: 16,
        lineHeight: '125%',
        color: '#222',
        paddingHorizontal: 16,
        paddingVertical: 16
    },
    comfortsWrap: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
})

export default ComfortsScreen
