import React from 'react'
import { StyleSheet, Text, View, Linking } from 'react-native'

import Footer from '../components/Footer'
import Window from '../components/Window'

const SupportScreen = () => {
    return (
        <Window style={{ flex: 1 }}>
            <View style={styles.container}>
                <Text style={styles.text}>Режим работы службы технической поддержки: пн-вс: с 9:00 по 21:00</Text>
                <Text style={styles.text}>{`\nПозвонить в поддержку:`}</Text>
                <Text style={{ color: 'blue', fontSize: 16, }} onPress={() => Linking.openURL('tel:+79142221179')}>+79142221179, </Text>
                <Text style={{ color: 'blue', fontSize: 16 }} onPress={() => Linking.openURL('tel:88003006998')}>88003006998</Text>
                {/* <Text style={styles.text}>WhatsApp, +79142221179, +79241671972</Text> */}
                <Text style={styles.text}>{`\nНаписать в WhatsApp, Telegram`}</Text>
                <Text style={{ color: 'blue', fontSize: 16 }} onPress={() => Linking.openURL('t.me/+79142221179')}>+79142221179, </Text>
                <Text style={{ color: 'blue', fontSize: 16 }} onPress={() => Linking.openURL('tel:+79241671972')}>+79241671972</Text>
            </View>
            <Footer />
        </Window>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16
    },
    text: {
        color: '#000',
        fontSize: 16
    },
})

export default SupportScreen