import React, { useState } from 'react'
import { ScrollView, Image, StyleSheet, Text, TouchableOpacity, View, Linking } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useMutation, useLazyQuery } from '@apollo/client'
import PickerSelect from 'react-native-picker-select'
import { Ionicons } from '@expo/vector-icons'
import { toast } from 'react-toastify';
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { FIND_UNIQUE_REQUEST_FOR_ADMIN, UPDATE_ONE_REQUEST } from '../gqls'

import { imageUrl } from '../utils/const'
import { useCompany } from '../hooks/company'

import Loading from '../components/Loading'
import Window from '../components/Window'

dayjs.locale('ru')

const statuses = [
    {
        label: 'Ждет подтверждения',
        value: '0'
    },
    {
        label: 'Оплачен',
        value: '1'
    },
    {
        label: 'Отменен',
        value: '3'
    },
    {
        label: 'Отменен гостем',
        value: '4'
    }
]

const RequestScreen = ({ route }) => {
    const { id } = route.params
    const navigation = useNavigation()
    const [selectStatus, setSelectStatus] = useState(null)
    const [_statusChangeHistory, _setStatusChangeHistory] = useState([])

    const [findUniqueRequestQuery, { data: findUniqueRequestData, loading: findUniqueRequestLoading, error: findUniqueRequestError }] = useLazyQuery(FIND_UNIQUE_REQUEST_FOR_ADMIN, {
        fetchPolicy: 'network-only',
    })

    useCompany({
        onCompleted: async ({ findCurrentCompany }) => {
            if (findCurrentCompany != null) {
                await findUniqueRequestQuery({
                    fetchPolicy: 'network-only',
                    variables: {
                        where: {
                            id,
                            object: {
                                is: {
                                    companyId: {
                                        equals: findCurrentCompany.id
                                    }
                                }
                            },
                        },
                    },
                    onCompleted: ({ findUniqueRequest }) => {
                        if (findUniqueRequest != null) {
                            _setStatusChangeHistory(findUniqueRequest.statusChangeHistory ? findUniqueRequest.statusChangeHistory : [])
                            setSelectStatus(findUniqueRequest.status)
                        } else
                            window.location.replace('/requests');
                    }
                })
            }
            else
                window.location.replace('/profile');
        },
        onError: () => {
            window.location.replace('/profile');
        }
    })

    const [updateOneRequestMutation, { loading: updateOneRequestLoading }] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: () => {
            toast('Изменение сохранено')
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                window.history.back()
            }
        }
    })

    if (findUniqueRequestLoading || findUniqueRequestError || !findUniqueRequestData) return <Loading />

    const { findUniqueRequest } = findUniqueRequestData

    const onSave = async () => {
        if (selectStatus !== '4') {
            var tempData = [];
            _statusChangeHistory.forEach(element => {
                tempData.push(element)
            });
            dayjs.extend(utc);
            dayjs.extend(tz);
            tempData.push(JSON.stringify({
                'date': dayjs().tz("Asia/Yakutsk").format('YYYY-MM-DD HH:mm:ss'),
                'status': selectStatus,
                'who': 'admin_web'
            }))
            _setStatusChangeHistory(tempData)
            await updateOneRequestMutation({
                variables: {
                    where: {
                        id
                    },
                    data: {
                        status: {
                            set: selectStatus
                        },
                        statusChangeHistory: tempData,
                    }
                },
            })
        } else {
            toast('Вы не можете отменить от имени гостя')
        }
    }

    return (
        <>
            <ScrollView style={styles.container}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <View style={styles.request} key={findUniqueRequest.id}>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Дата оформления</Text>
                            <Text>{dayjs(findUniqueRequest.createdAt).format('DD.MM.YY')}</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Дата и время брони</Text>
                            <Text>{dayjs(findUniqueRequest.reservedAt).format('DD.MM.YY')} {findUniqueRequest.schedule != null ? findUniqueRequest.schedule.startAt + '-' + findUniqueRequest.schedule.endAt : ''}</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Стоимость</Text>
                            <Text>{findUniqueRequest.price != null ? (findUniqueRequest.discount || findUniqueRequest.price) : (findUniqueRequest.schedule != null ? findUniqueRequest.schedule.price : '-')} ₽</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Имя пользователя</Text>
                            <Text>{findUniqueRequest.name}</Text>
                        </View>
                        <TouchableOpacity onPress={() => Linking.openURL(`tel:+${findUniqueRequest.phone}`)} activeOpacity={1} style={styles.group}>
                            <Text style={styles.requestTitle}>Телефон пользователя</Text>
                            <Text>+{findUniqueRequest.phone}</Text>
                        </TouchableOpacity>
                        {
                            findUniqueRequest.rating > 0 ? (
                                <>
                                    <View style={styles.group}>
                                        <Text style={styles.requestTitle}>Оценка</Text>
                                        <View style={styles.rateContainer}>
                                            {
                                                [1, 2, 3, 4, 5].map((object, index) => (
                                                    <View key={index}>
                                                        <Ionicons name='ios-star' size={28} color={findUniqueRequest.rating >= object ? '#E6B762' : '#D0D5DD'} />
                                                    </View>
                                                ))
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.group}>
                                        <Text style={styles.requestTitle}>Отзыв</Text>
                                        <Text>{findUniqueRequest.review}</Text>
                                    </View>
                                </>
                            ) : null
                        }
                        <PickerSelect
                            style={{
                                inputWeb: {
                                    height: 36,
                                    backgroundColor: '#EDEDED',
                                    borderRadius: 8,
                                    paddingLeft: 8
                                },
                            }}
                            placeholder={{}}
                            value={selectStatus}
                            onValueChange={(value) => setSelectStatus(value)}
                            items={statuses}
                        />
                        <TouchableOpacity onPress={() => navigation.navigate('Object', { id: findUniqueRequest.object.id })} style={styles.objectContainer}>
                            <Image source={findUniqueRequest.object.images_720.length > 0 ? { uri: imageUrl(findUniqueRequest.object.images_720[0]) } : { uri: imageUrl(findUniqueRequest.object.images[0]) }} style={styles.image} />
                            <Text style={styles.objectName}>{findUniqueRequest.object.name}</Text>
                        </TouchableOpacity>
                    </View>
                </Window>
            </ScrollView>
            <Window>
                <View style={styles.footer}>
                    <TouchableOpacity disabled={updateOneRequestLoading} onPress={onSave} style={styles.button}>
                        <Text style={styles.buttonText}>Сохранить</Text>
                    </TouchableOpacity>
                </View>
            </Window>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    request: {
        marginVertical: 16
    },
    title: {
        color: '#101828',
        fontSize: 17,
        fontWeight: '500',
        marginBottom: 12,
        marginHorizontal: 16
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8
    },
    headerTitle: {
        color: '#101828'
    },
    requestTitle: {
        fontSize: 12,
        marginBottom: 4,
        color: '#667085'
    },
    group: {
        marginBottom: 12
    },
    objectContainer: {
        marginVertical: 12,
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        height: 56,
        width: 56,
        borderRadius: 12,
        backgroundColor: '#D0D5DD',
        marginRight: 24
    },
    objectName: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 16
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    rateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4
    },
})

export default RequestScreen