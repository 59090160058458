import React from 'react'
import {
    View,
    StyleSheet,
    ActivityIndicator
} from 'react-native'

const Loading = ({ style }) => {
    return (
        <View style={[styles.container, style]}>
            <ActivityIndicator size='large' color='#E6B762' />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center'
    },
})

export default Loading