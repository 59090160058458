import React from 'react'
import { ScrollView, ImageBackground, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions, Dimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'

import { useQuery, useReactiveVar } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_MANY_OBJECT } from '../gqls'

import { imageUrl } from '../utils/const'
import { favoritesVar } from '../utils/cache'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const FavoritesScreen = () => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()
    const favorites = useReactiveVar(favoritesVar)

    const { data: findManyObjectData, loading: findManyObjectLoading, error: findManyObjectError } = useQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only',
        pollInterval: 5000,
        variables: {
            where: {
                id: {
                    in: favorites
                },
                publish: {
                    equals: true
                }
            },
            orderBy: {
                createdAt: 'desc'
            },
        }
    })

    const squareSize = width > 1024 ? 484 : width - 32

    if (findManyObjectLoading) return <Loading />
    if (findManyObjectError) return <Error />

    const { findManyObject } = findManyObjectData

    const addToFavorite = (id) => {
        favoritesVar([
            ...new Set([
                ...favoritesVar(),
                id
            ])
        ])
    }

    const removeToFavorite = (id) => {
        const favorites = favoritesVar().filter(object => object !== id)
        favoritesVar([
            ...favorites
        ])
    }

    const getMinPrice = (price) => {
        const prices = price.filter(object => object.status == true).map(object => parseInt(object.price))
        return Math.min(...prices)
    }

    const getRating = (requests) => {
        if (requests.length === 0) return (0).toFixed(2)
        const rating = requests.map(object => object.rating).reduce((a, b) => a + b) / requests.length
        if (rating === 0) return (0).toFixed(2)
        return rating.toFixed(2)
    }

    const getTags = (object) => {
        if (object.premium) {
            return (
                <View style={[styles.tagHot, { backgroundColor: '#BCC8B2' }]}>
                    <Text style={styles.hotText}>Интересно</Text>
                </View>
            )
        }
        if (object.hot) {
            return (
                <>
                    <View style={styles.tagHot}>
                        <Text style={styles.hotText}>Горячие</Text>
                    </View>
                    <View style={styles.tagDeadline}>
                        <MaterialCommunityIcons name='history' size={20} color='#000' />
                        <Text style={styles.deadlineText}>осталось 3 дня</Text>
                    </View>
                </>
            )
        }
        return null
    }

    const getPeople = (object) => {
        if (!object.people) return null
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}>
                <Ionicons style={{ marginTop: 1.5 }} name='ios-people-outline' size={23} color='#000' />
                <Text style={{ color: '#000', marginLeft: 4, fontSize: 16 }}>{object.people}</Text>
            </View>
        )
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginBottom: 24 }}>
                <Window style={{marginTop: windowWidth > 768 ? 20 : 0}}>
                    <View style={styles.objectsContainer}>
                        {
                            findManyObject.map((object, index) => (
                                <TouchableOpacity key={index} onPress={() => navigation.navigate('Object', { id: object.id })}>
                                    <ImageBackground style={[styles.objectImage, { height: squareSize, width: squareSize }]} source={object.images_720.length > 0 ? { uri: imageUrl(object.images_720[0]) } : { uri: imageUrl(object.images[0]) }}>
                                        {
                                            object.favorite ? (
                                                <TouchableOpacity onPress={() => removeToFavorite(object.id)} style={styles.favorite}>
                                                    <Ionicons name='ios-heart-sharp' size={24} color='#475467' />
                                                </TouchableOpacity>
                                            ) : (
                                                <TouchableOpacity onPress={() => addToFavorite(object.id)} style={styles.favorite}>
                                                    <Ionicons name='ios-heart-outline' size={24} color='#475467' />
                                                </TouchableOpacity>
                                            )
                                        }
                                        <View style={styles.tagsContainer}>
                                            {
                                                getTags(object)
                                            }
                                        </View>
                                    </ImageBackground>
                                    <View style={[styles.infoContainer, { justifyContent: 'space-between', marginTop: 8 }]}>
                                        <Text style={[styles.infoTitle, { maxWidth: squareSize - 114 }]}>{object.name}</Text>
                                        <View style={[styles.infoGroup, { maxWidth: 114 }]}>
                                            {
                                                getPeople(object)
                                            }
                                            {getRating(object.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3)) > 0 ?
                                                <View style={[styles.infoContainer, { justifyContent: 'space-between', alignItems: 'center' }]}>
                                                    <Ionicons name='ios-star' size={20} color='#000' />
                                                    <Text style={styles.rate}>{getRating(object.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3))}</Text>
                                                </View> : ""}
                                        </View>
                                    </View>
                                    <Text style={styles.price}>от {getMinPrice(object.schedules)} ₽</Text>
                                </TouchableOpacity>
                            ))
                        }
                        {
                            findManyObject.length === 0 ? (
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>Нет избранных</Text>
                                </View>
                            ) : null
                        }
                    </View>
                </Window>
            </ScrollView>
        </>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    searchContainer: {
        flexDirection: 'row',
        height: 56,
        borderRadius: 28,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        alignItems: 'center',
        marginTop: 12,
        marginHorizontal: 16,
        marginBottom: 12
    },
    searchIcon: {
        marginHorizontal: 12
    },
    searchTitle: {
        color: '#101828',
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 2
    },
    searchText: {
        color: '#98A2B3',
        fontSize: 12,
        fontWeight: '400'
    },
    objectsContainer: {
        gap: 24,
        marginHorizontal: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 12
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoGroup: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoTitle: {
        fontSize: 20,
        fontWeight: '500',
        color: '#101828',
        marginRight: 24
    },
    infoPeopleCount: {
        fontSize: 16,
        fontWeight: '400',
        color: '#101828',
        marginLeft: 2,
    },
    rate: {
        fontSize: 16,
        color: '#101828',
        marginLeft: 4
    },
    price: {
        color: '#101828',
        fontSize: 16,
        fontWeight: '500',
        marginTop: 9
    },
    favorite: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: '#F2F4F7',
        alignSelf: 'flex-end',
        margin: 16
    },
    tagsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 16
    },
    tagHot: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#E6B762',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotText: {
        color: '#FCFCFD',
        fontSize: 16,
        fontWeight: 'bold'
    },
    tagDeadline: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#EAECF0',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deadlineText: {
        color: '#000',
        fontSize: 14,
        fontWeight: '400',
        marginLeft: 4
    }
})

export default FavoritesScreen