import React, { useState, useEffect } from 'react'
import { ScrollView, Image, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions, Dimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { imageUrl } from '../utils/const'

import { FIND_MANY_POST } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Footer from '../components/Footer'
import Window from '../components/Window'

const FeedScreen = (props) => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()

    const squareSize = width > 1024 ? 484 : width - 32

    const [search, setSearch] = useState(undefined)

    useEffect(() => {
        const { params } = props.route
        setSearch(params?.search || undefined)
    }, [props.route.params])

    const { data: findManyPostData, loading: findManyPostLoading, error: findManyPostError } = useQuery(FIND_MANY_POST, {
        fetchPolicy: 'network-only',
        variables: {
            orderBy: {
                createdAt: 'desc'
            },
            where: search ? {
                OR: [
                    {
                        user: {
                            contains: search,
                            mode: 'insensitive'
                        },
                    },
                    {
                        text: {
                            contains: search,
                            mode: 'insensitive'
                        }
                    }
                ]
            } : undefined
        }
    })

    if (findManyPostLoading) return <Loading />
    if (findManyPostError) return <Error />

    const { findManyPost } = findManyPostData

    return (
        <ScrollView stickyHeaderIndices={[0]} style={styles.container} >
            <Window style={{ backgroundColor: '#fff', marginTop: windowWidth > 768 ? 80 : 0 }}>
                <TouchableOpacity onPress={() => navigation.navigate('Filter', { type: 'feeds', search })} style={styles.searchContainer}>
                    <Ionicons style={styles.searchIcon} name='ios-search' size={24} />
                    <View>
                        <Text style={styles.searchTitle}>Найти в ленте</Text>
                        <Text style={styles.searchText}>Поиск по тексту</Text>
                    </View>
                </TouchableOpacity>
            </Window>
            <Window>
                <View style={styles.feedContainer}>
                    {
                        findManyPost.map((object, index) => (
                            <TouchableOpacity key={index} onPress={() => navigation.navigate('Feed', { id: object.id })}>
                                <Image style={[styles.objectImage, { height: squareSize, width: squareSize }]} source={{ uri: imageUrl(object.image) }} />
                                <View style={[styles.infoContainer, { justifyContent: 'space-between', marginTop: 8 }]}>
                                    <Text style={styles.title}>{object.user}</Text>
                                    <Text style={styles.date}>{dayjs.unix(object.date).format('DD MMMM YYYY')}</Text>
                                </View>
                                <Text style={[styles.description, { width: squareSize }]}>{object.text ? `${object.text.slice(0, 120)}...` : ''}</Text>
                            </TouchableOpacity>
                        ))
                    }
                </View>
                <Footer />
            </Window>
        </ScrollView>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    searchContainer: {
        flexDirection: 'row',
        height: 56,
        borderRadius: 28,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 12,
        marginHorizontal: 16,
    },
    searchText: {
        color: '#98A2B3',
        fontSize: 12,
        fontWeight: '400'
    },
    searchIcon: {
        marginHorizontal: 12
    },
    searchTitle: {
        color: '#101828',
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 2
    },
    feedContainer: {
        gap: 24,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 24,
        minHeight: '100%',
        marginHorizontal: 16,
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontSize: 16,
        fontWeight: '500',
        color: '#101828'
    },
    date: {
        fontSize: 16,
        color: '#667085',
    },
    description: {
        color: '#101828',
        fontSize: 14,
        marginTop: 8
    },
    favorite: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: '#F2F4F7',
        alignSelf: 'flex-end',
        margin: 16
    },
    tagsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 16
    },
    tagHot: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#E6B762',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotText: {
        color: '#FCFCFD',
        fontSize: 16,
        fontWeight: 'bold'
    },
    tagDeadline: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#EAECF0',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deadlineText: {
        color: '#000',
        fontSize: 14,
        fontWeight: '400',
        marginLeft: 4
    }
})

export default FeedScreen