import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Footer from '../components/Footer'
import Window from '../components/Window'

const text = `Впервые в марте 2023 года на территории Республики Саха (Якутия) запущен интернет-агрегатор мест отдыха Domiki.Ykt.ru. С 2024 года сервис присоединился к экосистеме Ykt.Ru.

Цель сервиса – создать единое цифровое пространство для выбора отдыха на природе.

У нас вы сможете найти и забронировать места отдыха в сфере туризма - гостевые дома, туристические базы, беседки, шатры, туры и активные виды отдыха.

Приглашаем организаторов отдыха к сотрудничеству, вы можете связаться с нами позвонив или написав по WhatsApp по номеру +79142221179, или на e-mail: domikiykt@yandex.ru`

const AboutScreen = () => {
    return (
        <Window style={{ flex: 1 }}>
            <View style={styles.container}>
                <Text style={styles.text}>{text}</Text>
            </View>
            <Footer />
        </Window>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16
    },
    text: {
        color: '#000'
    },
})

export default AboutScreen