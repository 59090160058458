import React, { useEffect, useState } from 'react'
import { StatusBar, View, Platform, TouchableOpacity, Dimensions, Image } from 'react-native'
import { NavigationContainer, DefaultTheme, useNavigation } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { useReactiveVar } from '@apollo/client'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'

import MainScreen from '../screens/Main'
import ObjectsScreen from '../screens/Objects'
import ObjectScreen from '../screens/Object'
import ReserveScreen from '../screens/Reserve'
import ConfirmScreen from '../screens/Confirm'
import ReviewsScreen from '../screens/Reviews'
import ComfortsScreen from '../screens/Comforts'
import ProfileScreen from '../screens/Profile'
import PersonScreen from '../screens/Person'
import CodeScreen from '../screens/Code'
import FeedsScreen from '../screens/Feeds'
import FeedScreen from '../screens/Feed'
import ToursScreen from '../screens/Tours'
import TourScreen from '../screens/Tour'
import RequestsScreen from '../screens/Requests'
import RequestsArchiveScreen from '../screens/RequestsArchive'
import RequestScreen from '../screens/Request'
import FavoritesScreen from '../screens/Favorites'
import ReservationsScreen from '../screens/Reservations'
import ReservationScreen from '../screens/Reservation'
import ReviewScreen from '../screens/Review'
import PointsScreen from '../screens/Points'
import FilterScreen from '../screens/Filter'
import MyObjectsScreen from '../screens/MyObjects'
import MyObjectScreen from '../screens/MyObject'
import CloseScreen from '../screens/Close'
import AboutScreen from '../screens/About'
import RequisitesScreen from '../screens/Requisites'
import SupportScreen from '../screens/Support'
import PartnerScreen from '../screens/Partner'
import { favoritesVar, reservationsVar } from '../utils/cache'

const Stack = createNativeStackNavigator()
const Tab = createBottomTabNavigator()

const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: '#fff'
    },
}

const linking = {
    config: {
        screens: {
            Tab: {
                screens: {
                    Main: '',
                    ObjectsStack: {
                        screens: {
                            Objects: 'objects',
                            Object: 'objects/:id',
                            Reserve: 'reserve/:id',
                            Confirm: 'confirm',
                            Reviews: 'reviews/:id',
                            Comforts: 'comforts/:id',
                        }
                    },
                    FeedsStack: {
                        screens: {
                            Feeds: 'feeds',
                            Feed: 'feeds/:id',
                        }
                    },
                    ToursStack: {
                        screens: {
                            Tours: 'tours',
                            Tour: 'tours/:id',
                        }
                    },
                    ProfileStack: {
                        screens: {
                            Profile: 'profile',
                            Person: 'person',
                            Favorites: 'favorites',
                            Reservations: 'reservations',
                            Reservation: 'reservations/:id',
                            Review: 'review/:id',
                            Points: 'points',
                            MyObjects: 'myObjects',
                            MyObject: 'myObjects/:id',
                            Close: 'close/:id',
                            Requests: 'requests',
                            RequestsArchive: 'requests-archive',
                            Request: 'requests/:id'
                        }
                    },
                }
            },
            Code: 'code',
            Filter: 'filter',
            About: 'about',
            Requisites: 'requisites',
            Support: 'support',
            Partner: 'partner'
        },
    },
}

const FlexBottomBarForWeb = () => {
    const navigation = useNavigation()
    const [render, setRender] = useState(true)

    if (Platform.OS !== 'web') {
        return null
    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('state', () => {
            const route = navigation.getCurrentRoute()
            if (route && (route.name === 'Code' || route.name === 'Filter' || route.name === 'About' || route.name === 'Partner' || route.name === 'Requisites' || route.name === 'Support')) {
                setRender(false)
            } else {
                setRender(true)
            }
        })

        return unsubscribe
    }, [navigation])

    if (!render) return null

    return (
        <View
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                borderTopColor: 'rgb(216, 216, 216)',
                borderTopWidth: 1,
                backgroundColor: '#fff',
            }}
        />
    )
}

const ObjectsStackNavigator = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                headerTitleAlign: 'center',
                headerLeft: () => {
                    const navigation = useNavigation()
                    return (
                        <TouchableOpacity
                            style={{ paddingHorizontal: 16 }}
                            onPress={() => {
                                if (navigation.canGoBack()) {
                                    navigation.goBack()
                                } else {
                                    window.history.back()
                                }
                            }}
                        >
                            <Ionicons name='ios-chevron-back' size={24} color='#000' />
                        </TouchableOpacity>
                    )
                },
                headerStyle: {
                    borderBottomColor: '#D0D5DD',
                    borderBottomWidth: 1,
                    elevation: 0,
                    shadowOpacity: 0,
                }
            }}
        >
            <Stack.Screen
                options={{
                    title: 'Домики',
                }}
                name='Objects'
                component={ObjectsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Домик',
                }}
                name='Object'
                component={ObjectScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Бронирование',
                    headerShown: true
                }}
                name='Reserve'
                component={ReserveScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Оформление брони',
                    headerShown: true
                }}
                name='Confirm'
                component={ConfirmScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Отзывы',
                    headerShown: true
                }}
                name='Reviews'
                component={ReviewsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Удобства',
                    headerShown: true
                }}
                name='Comforts'
                component={ComfortsScreen}
            />
        </Stack.Navigator>
    )
}

const FeedsStackNavigator = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                options={{
                    title: 'Лента',
                }}
                name='Feeds'
                component={FeedsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Лента',
                }}
                name='Feed'
                component={FeedScreen}
            />
        </Stack.Navigator>
    )
}

const ToursStackNavigator = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                options={{
                    title: 'Туризм'
                }}
                name='Tours'
                component={ToursScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Тур',
                }}
                name='Tour'
                component={TourScreen}
            />
        </Stack.Navigator>
    )
}

const ProfileStackNavigator = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                headerTitleAlign: 'center',
                headerLeft: () => {
                    const navigation = useNavigation()
                    return (
                        <TouchableOpacity
                            style={{ paddingHorizontal: 16 }}
                            onPress={() => {
                                if (navigation.canGoBack()) {
                                    navigation.goBack()
                                } else {
                                    window.history.back()
                                }
                            }}
                        >
                            <Ionicons name='ios-chevron-back' size={24} color='#000' />
                        </TouchableOpacity>
                    )
                },
                headerStyle: {
                    borderBottomColor: '#D0D5DD',
                    borderBottomWidth: 1,
                    elevation: 0,
                    shadowOpacity: 0,
                }
            }}
        >
            <Stack.Screen
                options={{
                    title: 'Профиль',
                }}
                name='Profile'
                component={ProfileScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Личные данные',
                    headerShown: true
                }}
                name='Person'
                component={PersonScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Избранные',
                    headerShown: true
                }}
                name='Favorites'
                component={FavoritesScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Мои брони',
                    headerShown: true
                }}
                name='Reservations'
                component={ReservationsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Моя бронь',
                    headerShown: true
                }}
                name='Reservation'
                component={ReservationScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Оставить отзыв',
                    headerShown: true
                }}
                name='Review'
                component={ReviewScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Мои бонусы',
                    headerShown: true
                }}
                name='Points'
                component={PointsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Мои обьекты',
                    headerShown: true
                }}
                name='MyObjects'
                component={MyObjectsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Панель управления',
                    headerShown: true
                }}
                name='MyObject'
                component={MyObjectScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Закрыть бронь',
                    headerShown: true
                }}
                name='Close'
                component={CloseScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Брони',
                    headerShown: true
                }}
                name='Requests'
                component={RequestsScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Архив',
                    headerShown: true
                }}
                name='RequestsArchive'
                component={RequestsArchiveScreen}
            />
            <Stack.Screen
                options={{
                    title: 'Бронь',
                    headerShown: true
                }}
                name='Request'
                component={RequestScreen}
            />
        </Stack.Navigator>
    )
}

const TabNavigator = () => {
    return (
        windowWidth > 768 ? (
            <Tab.Navigator
                backBehavior='history'
                screenOptions={{
                    lazy: true,
                    tabBarActiveTintColor: '#1F2A37',
                    tabBarInactiveTintColor: '#8C8C8C',
                    tabBarStyle: {
                        backgroundColor: '#fff',
                        height: 72,
                        zIndex: 1,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingHorizontal: 24,
                        top: 0,
                        bottom: 'unset',
                        position: 'absolute',
                        maxWidth: 1024,
                        marginHorizontal: 'auto',
                        borderBottomLeftRadius: 24,
                        borderBottomRightRadius: 24,


                        // shadowColor: "#000",
                        // shadowOffset: {
                        //     width: 0,
                        //     height: 2,
                        // },
                        // shadowOpacity: 0.25,
                        // shadowRadius: 3.84,
                        // elevation: 5,
                    },
                    tabBarLabelStyle: {
                        paddingTop: 0,
                        fontSize: 16,
                        margin: 0,
                        fontWeight: 500,
                        paddingBottom: 0, // Отступ для текста
                    },
                    tabBarItemStyle: {
                        justifyContent: 'flex-start',
                        flex: 'initial',
                        marginHorizontal: 24
                    },
                    headerShown: false,
                }}
            >
                <Tab.Screen
                    options={{
                        tabBarLabel: '',
                        title: 'Главная',
                        tabBarItemStyle: {
                            justifyContent: 'flex-start',
                            flex: 'initial',
                            marginHorizontal: 0
                        },
                        tabBarIconStyle: {
                            width: 167,
                            marginRight: 48,
                        },
                        tabBarIcon: ({ color }) => (
                            <Image
                                source={require('../../assets/logo-header.svg')} // путь к вашему PNG файлу
                                style={{ width: 167.15, height: 32, objectFit: 'contain' }} // добавление tintColor для изменения цвета
                            />
                        ),
                        headerShown: false,
                    }}
                    name='Main'
                    component={MainScreen}
                />
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Домики',
                        title: 'Домики',
                        tabBarIcon: ({ color }) => ''
                    }}
                    name='ObjectsStack'
                    component={ObjectsStackNavigator}
                    style={{
                        justifyContent: 'normal',
                    }}
                />
                {/* <Tab.Screen
                    options={{
                        tabBarLabel: 'Лента',
                        title: 'Лента',
                        tabBarIcon: ({ color }) => '',
                    }}
                    name='FeedsStack'
                    component={FeedsStackNavigator}
                /> */}
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Туризм',
                        title: 'Туризм',
                        tabBarIcon: ({ color }) => ''
                    }}
                    name='ToursStack'
                    component={ToursStackNavigator}
                />
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Профиль',
                        title: 'Профиль',
                        tabBarItemStyle: {
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            top: 0,
                        },
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons style={{marginRight: 32}} name='account-outline' size={24} color={color} />
                    }}
                    name='ProfileStack'
                    component={ProfileStackNavigator}
                />
            </Tab.Navigator>
        ) : (
            <Tab.Navigator
                backBehavior='history'
                screenOptions={{
                    lazy: true,
                    tabBarActiveTintColor: '#1F2A37',
                    tabBarInactiveTintColor: '#8C8C8C',
                    tabBarStyle: {
                        backgroundColor: '#fff',
                        zIndex: 1,
                        paddingTop: 10,
                        top: 0,
                        bottom: windowWidth > 768 ? 'unset' : 0,
                        position: windowWidth > 768 ? 'absolute' : 'relative',
                    },
                    tabBarLabelStyle: {
                        paddingTop: 10,
                        fontSize: 12,
                        paddingBottom: 2, // Отступ для текста
                    },
                    headerShown: false,
                }}
            >
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Главная',
                        title: 'Главная',
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons name='home' size={24} color={color} />,
                        headerShown: false,
                    }}
                    name='Main'
                    component={MainScreen}
                />
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Домики',
                        title: 'Домики',
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons name='home-group' size={24} color={color} />
                    }}
                    name='ObjectsStack'
                    component={ObjectsStackNavigator}
                />
                {/* <Tab.Screen
                    options={{
                        tabBarLabel: 'Лента',
                        title: 'Лента',
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons name='newspaper-variant-outline' size={24} color={color} />
                    }}
                    name='FeedsStack'
                    component={FeedsStackNavigator}
                /> */}
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Туризм',
                        title: 'Туризм',
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons name='compass-outline' size={24} color={color} />
                    }}
                    name='ToursStack'
                    component={ToursStackNavigator}
                />
                <Tab.Screen
                    options={{
                        tabBarLabel: 'Профиль',
                        title: 'Профиль',
                        tabBarIcon: ({ color }) => <MaterialCommunityIcons name='account' size={24} color={color} />
                    }}
                    name='ProfileStack'
                    component={ProfileStackNavigator}
                />
            </Tab.Navigator>
        )
        
    )
}

// const TabNavigator = () => {
//     return (
//         <Tab.Navigator
//             backBehavior='history'
//             screenOptions={{
//                 lazy: true,
//                 tabBarActiveTintColor: '#E6B762',
//                 tabBarInactiveTintColor: '#667085',
//                 tabBarStyle: {
//                     backgroundColor: '#EAECF0',
//                     zIndex: -1
//                 },
//                 headerShown: false,
//             }}
//         >
//             <Tab.Screen
//                 options={{
//                     tabBarLabel: 'Главная',
//                     title: 'Главная',
//                     tabBarIcon: ({ color }) => <MaterialCommunityIcons name='home' size={24} color={color} />,
//                     headerShown: false,
//                 }}
//                 name='Main'
//                 component={MainScreen}
//             />
//             <Tab.Screen
//                 options={{
//                     tabBarLabel: 'Домики',
//                     title: 'Домики',
//                     tabBarIcon: ({ color }) => <MaterialCommunityIcons name='home-group' size={24} color={color} />
//                 }}
//                 name='ObjectsStack'
//                 component={ObjectsStackNavigator}
//             />
//             <Tab.Screen
//                 options={{
//                     tabBarLabel: 'Лента',
//                     title: 'Лента',
//                     tabBarIcon: ({ color }) => <MaterialCommunityIcons name='newspaper-variant-outline' size={24} color={color} />
//                 }}
//                 name='FeedsStack'
//                 component={FeedsStackNavigator}
//             />
//             <Tab.Screen
//                 options={{
//                     tabBarLabel: 'Туризм',
//                     title: 'Туризм',
//                     tabBarIcon: ({ color }) => <MaterialCommunityIcons name='compass-outline' size={24} color={color} />
//                 }}
//                 name='ToursStack'
//                 component={ToursStackNavigator}
//             />
//             <Tab.Screen
//                 options={{
//                     tabBarLabel: 'Профиль',
//                     title: 'Профиль',
//                     tabBarIcon: ({ color }) => <MaterialCommunityIcons name='account' size={24} color={color} />
//                 }}
//                 name='ProfileStack'
//                 component={ProfileStackNavigator}
//             />
//         </Tab.Navigator>
//     )
// }


const Router = () => {
    const favorites = useReactiveVar(favoritesVar)
    const reservations = useReactiveVar(reservationsVar)

    useEffect(() => {
        const restoreCache = async () => {
            const favoritesCache = await AsyncStorage.getItem('favorites')
            const favoritesData = JSON.parse(favoritesCache)
            favoritesVar([...new Set(favoritesData)])

            const reservationsCache = await AsyncStorage.getItem('reservations')
            const reservationsData = parseInt(reservationsCache || 0)
            reservationsVar(reservationsData)
        }
        restoreCache()
    }, [])

    useEffect(() => {
        const saveCache = async () => {
            await AsyncStorage.setItem('favorites', JSON.stringify(favorites))
            await AsyncStorage.setItem('reservations', JSON.stringify(reservations))
        }
        saveCache()
    }, [favorites, reservations])

    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            <NavigationContainer theme={theme} linking={linking}>
                <View style={{ flex: 1 }}>
                    <FlexBottomBarForWeb />
                    <View style={{ flex: 1, overflow: 'hidden' }}>
                        <Stack.Navigator
                            screenOptions={{
                                headerShown: false,
                                headerTitleAlign: 'center',
                                headerLeft: () => {
                                    const navigation = useNavigation()
                                    return (
                                        <TouchableOpacity
                                            style={{ paddingHorizontal: 16 }}
                                            onPress={() => {
                                                if (navigation.canGoBack()) {
                                                    navigation.goBack()
                                                } else {
                                                    window.history.back()
                                                }
                                            }}
                                        >
                                            <Ionicons name='ios-chevron-back' size={24} color='#000' />
                                        </TouchableOpacity>
                                    )
                                },
                                headerStyle: {
                                    borderBottomColor: '#D0D5DD',
                                    borderBottomWidth: 1,
                                    elevation: 0,
                                    shadowOpacity: 0,
                                }
                            }}
                        >
                            <Stack.Screen
                                name='Tab'
                                component={TabNavigator}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'Подтвердить номер',
                                    headerShown: true
                                }}
                                name='Code'
                                component={CodeScreen}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'Фильтр',
                                    headerShown: true,
                                }}
                                name='Filter'
                                component={FilterScreen}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'О нас',
                                    headerShown: true
                                }}
                                name='About'
                                component={AboutScreen}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'Реквизиты',
                                    headerShown: true
                                }}
                                name='Requisites'
                                component={RequisitesScreen}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'Техподдержка',
                                    headerShown: true
                                }}
                                name='Support'
                                component={SupportScreen}
                            />
                            <Stack.Screen
                                options={{
                                    title: 'Стать партнером',
                                    headerShown: true
                                }}
                                name='Partner'
                                component={PartnerScreen}
                            />
                        </Stack.Navigator>
                        <StatusBar />
                    </View>
                </View>
            </NavigationContainer>
        </GestureHandlerRootView>
    )
}

const windowWidth = Dimensions.get('window').width;

export default Router
