import React, { useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, TextInput, Platform, Dimensions } from 'react-native'
import { useMutation } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

import { useUser } from '../hooks/user'

import { UPDATE_ONE_USER } from '../gqls'

const PersonScreen = () => {
    const navigation = useNavigation()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const { user, loading: userLoading, error: userError } = useUser({
        onCompleted: ({ findCurrentUser }) => {
            if (findCurrentUser.name) setName(findCurrentUser.name)
            if (findCurrentUser.email) setEmail(findCurrentUser.email)
        }
    })

    const [updateOneUserMutation, { loading: updateOneUserLoading, error: updateOneUserError }] = useMutation(UPDATE_ONE_USER, {
        onCompleted: () => {
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                window.history.back()
            }
        }
    })

    if (updateOneUserLoading || userLoading) {
        return <Loading />
    }

    if (updateOneUserError || userError) {
        return <Error />
    }

    const onSave = async () => {
        await updateOneUserMutation({
            variables: {
                where: {
                    id: user.id
                },
                data: {
                    name: {
                        set: name
                    },
                    email: {
                        set: email
                    },
                }
            }
        })
    }

    return (
        <Window style={[styles.container, {marginTop: windowWidth > 768 ? 20 : 0}]}>
            <TextInput value={name} onChangeText={(value) => setName(value)} style={styles.input} placeholder='Имя' placeholderTextColor='#667085' />
            <TextInput value={email} onChangeText={(value) => setEmail(value)} style={styles.input} placeholder='Электронная почта' placeholderTextColor='#667085' />
            <TouchableOpacity onPress={onSave} style={styles.button}>
                <Text style={styles.buttonText}>Сохранить</Text>
            </TouchableOpacity>
        </Window>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 32,
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 16,
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#101828',
        marginTop: 24
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    }
})

export default PersonScreen