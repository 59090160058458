import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, TextInput, View } from 'react-native'

import IMask from 'imask'
import { useNavigation } from '@react-navigation/native'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Loading from '../components/Loading'
import Window from '../components/Window'

import { useUser } from '../hooks/user'
import { useCompany } from '../hooks/company'
import { SEND_USER_CODE } from '../gqls'

const codeMask = IMask.createMask({
    mask: '0000'
})

const CodeScreen = ({ route }) => {
    const { phone } = route.params
    const { returnUrl } = route.params


    const [code, setCode] = useState('')
    const [active,setActive] = useState(false)
    const [buttonText,setbuttonText] = useState('Отправить')

    const { refetch: refetchUser } = useUser()
    const { refetch: refetchCompany } = useCompany()

    const [sendUserCodeMutation, { loading }] = useMutation(SEND_USER_CODE, {
        onCompleted: async ({ sendUserCode }) => {
            const { token } = sendUserCode
            if (token) {
                await AsyncStorage.setItem('token', token)
                if (returnUrl != null)
                    window.location.replace(returnUrl)
                else
                    window.location.replace('/profile')
                refetchUser()
                refetchCompany()
            }
        },
        onError: e => {
            setbuttonText('Отправить')
            setActive(false)
            if (e.message == 'code incorrect')
                toast('Неправильно введен код')
            else
                toast('Что то пошло не так, повторите попытку позже')
        }
    })

    const onChangeCode = (value) => {
        const maskedValue = codeMask.resolve(value)
        setCode(maskedValue)
    }

    const onSubmit = async () => {
        if (code.length === 4) {
            setActive(true)
            setbuttonText('Подождите')
            await sendUserCodeMutation({
                variables: {
                    data: {
                        phone,
                        code
                    }
                }
            })
        } else {
            toast('Неправильно введен код')
        }
    }

    // if (loading) {
    //     return <Loading />
    // }

    return (
        <Window style={styles.container}>
            {/* {loading?(
                <Loading/>
            ):(
            <> */}
                <Text style={styles.text}>Введите код, отправленный на +{phone}</Text>
                <TextInput value={code} onChangeText={onChangeCode} style={styles.input} placeholder='Код' placeholderTextColor='#667085' />
                <TouchableOpacity onPress={onSubmit} style={styles.button} disabled={active}>
                    <Text style={styles.buttonText}>{buttonText}</Text>
                </TouchableOpacity>
            {/* </>
            )} */}
           
        </Window>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 16
    },
    text: {
        color: '#667085',
        marginBottom: 24
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 32
    },
})

export default CodeScreen