import React from 'react'
import {
    View,
    StyleSheet
} from 'react-native'

const Window = ({ style, children }) => {
    return (
        <View style={[styles.container, style]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 1024,
        width: '100%',
        alignSelf: 'center',
    },
})

export default Window