import { useQuery } from '@apollo/client'
import { FIND_CURRENT_USER } from '../gqls'

export const useUser = (params = {}) => {
    const { onCompleted = () => { }, onError = () => { }, fetchPolicy = 'network-only' } = params

    const { data, loading, error, refetch } = useQuery(FIND_CURRENT_USER, {
        onCompleted,
        onError,
        fetchPolicy,
        nextFetchPolicy: 'network-only',
    })

    const user = data ? data.findCurrentUser : null

    return {
        loading,
        error,
        user,
        refetch
    }
}