import React, { useState, useRef } from 'react'
import { ScrollView, Share, Image, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions, Linking, Platform, Dimensions } from 'react-native'
import * as Clipboard from "expo-clipboard"
import { toast } from 'react-toastify'

import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { useQuery } from '@apollo/client'
import FsLightbox from 'fslightbox-react'

import { imageUrl } from '../utils/const'

import { FIND_UNIQUE_TOUR } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const TourScreen = ({ route }) => {
    const { id } = route.params
    const { width } = useWindowDimensions()
    const navigation = useNavigation()
    const scrollRef = useRef()
    const [toggler, setToggler] = useState(false)

    const squareSize = width > 1024 ? 480 : width
    const dynamicWidth = width > 1024 ? 1024 : width
    const dynamicWidthTourImage = width > 1024 ? 1024 : width - 0
    const isMobile = width <= 1024;

    const [carouselIndex, setCarouselndex] = useState(0)

    const isDesktop = width > 768;

    const { data: findUniqueTourData, loading: findUniqueTourLoading, error: findUniqueTourError } = useQuery(FIND_UNIQUE_TOUR, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        }
    })

    if (findUniqueTourLoading) return <Loading />
    if (findUniqueTourError) return <Error />

    const { findUniqueTour } = findUniqueTourData

    const images = findUniqueTour?.images_1024.length > 0 ? findUniqueTour?.images_1024.map(object => imageUrl(object)) || [] : findUniqueTour?.images?.map(object => imageUrl(object)) || []

    const handleOnScroll = (event) => {
        const currentIndex = parseInt(event.nativeEvent.contentOffset.x / dynamicWidth)
        setCarouselndex(currentIndex)
    }

    const onScrollNext = (length) => {
        scrollRef.current.index = scrollRef.current.index || 0
        if (length > (scrollRef.current.index + 1)) {
            scrollRef.current.index = scrollRef.current.index + 1
            scrollRef.current.scrollTo({
                x: scrollRef.current.index * dynamicWidth,
                animation: false
            })
        }
    }

    const onScrollPreview = () => {
        scrollRef.current.index = scrollRef.current.index || 0
        if (scrollRef.current.index !== 0) {
            scrollRef.current.index = scrollRef.current.index - 1
            scrollRef.current.scrollTo({
                x: scrollRef.current.index * dynamicWidth,
                animation: false
            })
        }
    }

    const openImage = () => {
        setToggler(prev => !prev)
    }

    const onShare = async () => {
        await Clipboard.setStringAsync(window.location.href)
        toast(`Ссылка скопирована`)
        // try {
        //   const result = Share.share({
        //     title: 'Поделиться туром',
        //     message: '', 
        //     url: window.location.href
        //   });
        //   if (result.action === Share.sharedAction) {
        //     if (result.activityType) {
        //       // shared with activity type of result.activityType
        //     } else {
        //       // shared
        //     }
        //   } else if (result.action === Share.dismissedAction) {
        //     // dismissed
        //   }
        // } catch (error) {
        //     await Clipboard.setStringAsync(window.location.href)
        //     toast(`Ссылка скопировано.`)
        // }
    };

    const takesRender = () => {
        return findUniqueTour.takes.length > 0 ?
            <View>
                <View style={styles.infoContainer}>
                    <Image style={{ height: 24, width: 24 }} source={imageUrl('backpack.svg')} />
                    <Text style={[styles.infoText, { fontWeight: '500' }]}>Что нужно с собой взять</Text>
                </View>
                {
                    findUniqueTour.takes.map((object, index) => (
                        <Text key={index} style={styles.clipboard}>  •  {object}</Text>
                    ))
                }
                <View style={styles.line} />
            </View> : ""
    }

    const includesRender = () => {
        return findUniqueTour.includes.length > 0 ?
            <View>
                <View style={styles.infoContainer}>
                    <Image style={{ height: 24, width: 24 }} source={imageUrl('check.svg')} />
                    <Text style={[styles.infoText, { fontWeight: '500' }]}>В стоимость входит</Text>
                </View>
                {
                    findUniqueTour.includes.map((object, index) => (
                        <Text key={index} style={styles.clipboard}>  •  {object}</Text>
                    ))
                }
                <View style={styles.line} />
            </View> : ""
    }

    const transportationsRender = () => {
        return findUniqueTour.transportations.length > 0 ?
            <View>
                <View style={styles.infoContainer}>
                    <Image style={{ height: 24, width: 24 }} source={imageUrl('directions_bus.svg')} />
                    <Text style={[styles.infoText, { fontWeight: '500' }]}>Вид транспорта</Text>
                </View>
                {
                    findUniqueTour.transportations.map((object, index) => (
                        <Text key={index} style={styles.clipboard}>  •  {object}</Text>
                    ))
                }
                <View style={styles.line} />
            </View> : ""
    }
    const datesRender = () => {
        return findUniqueTour.dates.length > 0 ?
            <View style={styles.infoContainer}>
                <Image style={{ height: 24, width: 24 }} source={imageUrl('calendar-day-outline.svg')} />
                <Text style={styles.infoTextGray}>Даты набора групп:</Text>
                <Text style={styles.infoText}>{findUniqueTour.dates.join(', ')}</Text>
            </View> : ""
    }

    const peopleRender = () => {
        return findUniqueTour.people != null && findUniqueTour.people.length != 0 ?
            <View>
                <View style={styles.infoContainer}>
                    <Image style={{ height: 24, width: 24 }} source={imageUrl('groups.svg')} />
                    <Text style={[styles.infoText, { fontWeight: '500' }]}>Количество людей</Text>
                </View>
                <View style={styles.infoContainer}>
                    <Text style={styles.infoText}>{findUniqueTour.people}</Text>
                </View>
                <View style={styles.line} />
            </View> : ""
    }

    const lastingRender = () => {
        return findUniqueTour.lasting != null && findUniqueTour.lasting.length != 0 ?
            <View style={styles.infoContainer}>
                <Image style={{ height: 24, width: 24 }} source={imageUrl('clock-outline.svg')} />
                <Text style={styles.infoTextGray}>Продолжительность:</Text>
                <Text style={styles.infoText}>{findUniqueTour.lasting}</Text>
            </View> : ""
    }

    const scheduleRender = () => {
        return findUniqueTour.schedule != null && findUniqueTour.schedule.length != 0 ?
            <View>
                <View style={styles.infoContainer}>
                    <Image style={{ height: 24, width: 24 }} source={imageUrl('text_snippet.svg')} />
                    <Text style={[styles.infoText, { fontWeight: '500' }]}>Программа тура</Text>
                </View>
                <div className='schedule' dangerouslySetInnerHTML={{ __html: findUniqueTour.schedule }} />
            </View> : ""
    }

    var infoTourPaddindTop = isMobile ? squareSize - 30 : squareSize
    var counterPaddindBottom = isMobile ? 30 : 0

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={{ marginBottom: 24 }}>
                <Window>
                    {isDesktop ? (
                    <View style={{marginTop: windowWidth > 768 ? 50 : 0}}>
                        <View style={styles.objectBtnWrap}>
                            <TouchableOpacity
                                style={styles.back}
                                onPress={() => {
                                    if (isMobile)
                                        window.history.back()
                                    else
                                        window.open("/tours", "_self")
                                }}
                            >
                                <Ionicons name='ios-chevron-back-outline' size={20} color='#111928' />
                            </TouchableOpacity>

                            <View style={{gap: 12, flexDirection: 'row'}}>
                                <TouchableOpacity onPress={onShare} style={styles.copyLink}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M14.3506 10.625L14.9756 9.99999C16.3563 8.61928 16.3563 6.38071 14.9756 5V5C13.5949 3.61929 11.3563 3.61929 9.97559 5L9.35059 5.625M10.6006 14.375L9.97559 15C8.59488 16.3807 6.3563 16.3807 4.97559 15V15C3.59488 13.6193 3.59488 11.3807 4.97559 9.99999L5.60059 9.37499" stroke="black" stroke-width="1.47608" stroke-linecap="round"/>
                                        <path d="M11.8506 8.125L8.10059 11.875" stroke="black" stroke-width="1.47608" stroke-linecap="round"/>
                                    </svg>
                                </TouchableOpacity>
                            </View>
                        </View> 
                        {
                            images.length == 1 ? (
                                <View>
                                    <View style={styles.desktopImageWrap}>
                                        {
                                            images.map((object, index) => (
                                                <View style={styles.desktopImageWrap}>
                                                    <TouchableOpacity style={{width: '100%', height: 480}} onPress={openImage} activeOpacity={1}>
                                                        <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                    </TouchableOpacity>
                                                </View>
                                            ))
                                        }
                                    </View>   
                                    <View style={styles.tagsContainer}>
                                        <View style={styles.countContainer}>
                                            <Text style={styles.countText}>1/{findUniqueTour.images.length}</Text>
                                        </View>
                                    </View>
                                </View>
                            ) : images.length == 2 ? (
                                <View>
                                    <View style={styles.desktopImageWrap}>
                                        {
                                            images.slice(0, 2).map((object, index) => (
                                                <TouchableOpacity style={{width: '50%', height: 480}} onPress={openImage} activeOpacity={1}>
                                                    <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                </TouchableOpacity>
                                            ))
                                        }
                                    </View>   
                                    <View style={styles.tagsContainer}>
                                        <View style={styles.countContainer}>
                                            <Text style={styles.countText}>2/{findUniqueTour.images.length}</Text>
                                        </View>
                                    </View>
                                </View>
                            ) : images.length == 3 ? (
                                <View>
                                    <View style={styles.desktopImageWrap}>
                                        <View style={{width: '100%', flexDirection: 'row', gap: 8}}>
                                            {
                                                images.slice(0, 1).map((object, index) => (
                                                    <TouchableOpacity style={styles.firstObjectImage} onPress={openImage} activeOpacity={1}>
                                                        <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                    </TouchableOpacity>
                                                ))
                                            }
                                            <View style={styles.otherObjectImage}>
                                                {
                                                    images.slice(1, 3).map((object, index) => (
                                                        <TouchableOpacity style={{ height: '50%', width: windowWidth <= 830 ? '48%' : '49%' }} onPress={openImage} activeOpacity={1}>
                                                            <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                        </TouchableOpacity>
                                                    ))
                                                }
                                                {
                                                    images.slice(3, 4).map((object, index) => (
                                                        <TouchableOpacity style={{ height: '50%', width: '100%' }} onPress={openImage} activeOpacity={1}>
                                                            <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                        </TouchableOpacity>
                                                    ))
                                                }
                                            </View>
                                        </View>
                                    </View>   
                                    <View style={styles.tagsContainer}>
                                        <View style={styles.countContainer}>
                                            <Text style={styles.countText}>3/{findUniqueTour.images.length}</Text>
                                        </View>
                                    </View>
                                </View>
                            ) : images.length == 4 ? (
                                <View>
                                    <View style={styles.desktopImageWrap}>
                                        <View style={{width: '100%', flexDirection: 'row', gap: 8}}>
                                            {
                                                images.slice(0, 1).map((object, index) => (
                                                    <TouchableOpacity style={styles.firstObjectImage} onPress={openImage} activeOpacity={1}>
                                                        <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                    </TouchableOpacity>
                                                ))
                                            }
                                            <View style={styles.otherObjectImage}>
                                                {
                                                    images.slice(1, 3).map((object, index) => (
                                                        <TouchableOpacity style={{ height: '50%', width: '100%' }} onPress={openImage} activeOpacity={1}>
                                                            <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                        </TouchableOpacity>
                                                    ))
                                                }
                                            </View>
                                        </View>
                                    </View>   
                                    <View style={styles.tagsContainer}>
                                        <View style={styles.countContainer}>
                                            <Text style={styles.countText}>4/{findUniqueTour.images.length}</Text>
                                        </View>
                                    </View>
                                </View>
                            ) : (
                                <View>
                                    <View style={styles.desktopImageWrap}>
                                        <View style={{width: '100%', flexDirection: 'row', gap: 8}}>
                                            {
                                                images.slice(0, 1).map((object, index) => (
                                                    <TouchableOpacity style={styles.firstObjectImage} onPress={openImage} activeOpacity={1}>
                                                        <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                    </TouchableOpacity>
                                                ))
                                            }
                                            <View style={styles.otherObjectImage}>
                                                {
                                                    images.slice(1, 5).map((object, index) => (
                                                        <TouchableOpacity style={{ height: '50%', width: windowWidth <= 830 ? '48%' : '49%' }} onPress={openImage} activeOpacity={1}>
                                                            <Image key={index} style={{ height: '100%', width: '100%' }} source={{ uri: object }} />
                                                        </TouchableOpacity>
                                                    ))
                                                }
                                            </View>
                                        </View>
                                    </View>    
                                    <View style={styles.tagsContainer}>
                                        <View style={styles.countContainer}>
                                            <Text style={styles.countText}>5/{findUniqueTour.images.length}</Text>
                                        </View>
                                    </View>
                                </View>
                            )
                        }
                    </View>
                    ) : (
                    <View>
                        <ScrollView
                            ref={scrollRef}
                            style={[styles.objectImage, { height: squareSize }]}
                            scrollEventThrottle={160}
                            onScroll={handleOnScroll}
                            horizontal
                            pagingEnabled
                            showsHorizontalScrollIndicator={false}
                            decelerationRate={0}
                            snapToAlignment='center'
                        >
                            {
                                images.map((object, index) => (
                                    <TouchableOpacity onPress={openImage} activeOpacity={1}>
                                        <Image key={index} style={{ height: squareSize, width: dynamicWidthTourImage }} source={{ uri: object }} />
                                    </TouchableOpacity>
                                ))
                            }
                        </ScrollView>
                        <View style={styles.head}>
                            <TouchableOpacity
                                style={styles.back}
                                onPress={() => {
                                    if (isMobile)
                                        window.history.back()
                                    else
                                        window.open("/tours", "_self")
                                }}
                            >
                                <Ionicons name='ios-chevron-back-outline' size={20} color='#111928' />
                            </TouchableOpacity>
                            <View style={{flexDirection: 'row'}}>
                                <TouchableOpacity onPress={onShare} style={styles.copyLink}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M14.3506 10.625L14.9756 9.99999C16.3563 8.61928 16.3563 6.38071 14.9756 5V5C13.5949 3.61929 11.3563 3.61929 9.97559 5L9.35059 5.625M10.6006 14.375L9.97559 15C8.59488 16.3807 6.3563 16.3807 4.97559 15V15C3.59488 13.6193 3.59488 11.3807 4.97559 9.99999L5.60059 9.37499" stroke="black" stroke-width="1.47608" stroke-linecap="round"/>
                                        <path d="M11.8506 8.125L8.10059 11.875" stroke="black" stroke-width="1.47608" stroke-linecap="round"/>
                                    </svg>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.objectImagesPaginationWrap}>
                            {
                                findUniqueTour.images.map((object, index) => (
                                    <View style={[styles.objectImagesPagination, carouselIndex === index && styles.objectImagesPaginationActive]} key={index}></View>
                                ))
                            }
                        </View>
                        <View style={styles.tagsContainer}>
                            <View style={styles.countContainer}>
                                <Text style={styles.countText}>{carouselIndex + 1}/{findUniqueTour.images.length}</Text>
                            </View>
                        </View>
                    </View>
                    )}
                    <View>
                        <View style={[styles.tourInfo]}>
                            <View>
                                <Text style={styles.title}>{findUniqueTour.name}</Text>
                                <View style={styles.infoContainer}>
                                    <Image style={{ height: 24, width: 24 }} source={imageUrl('icon-map-pin.svg')} />
                                    <Text style={styles.infoTextGray}>Местность:</Text>
                                    <Text style={styles.infoText}>{findUniqueTour.location}</Text>
                                </View>
                                <View style={styles.infoContainer}>
                                    <Image style={{ height: 24, width: 24 }} source={imageUrl('account_circle.svg')} />
                                    <Text style={styles.infoTextGray}>Организатор:</Text>
                                    <Text style={styles.infoText}>{findUniqueTour.organization}</Text>
                                </View>
                                {
                                    datesRender()
                                }
                                {
                                    lastingRender()
                                }
                                <div className='schedule' dangerouslySetInnerHTML={{ __html: findUniqueTour.description }} />
                                <View style={styles.line} />
                                <View style={styles.infoContainer}>
                                    <Image style={{ height: 24, width: 24 }} source={imageUrl('solar_banknote.svg')} />
                                    <Text style={[styles.infoText, { fontWeight: '500' }]}>Цена</Text>
                                </View>
                                <View style={styles.infoContainer}>
                                    <Text style={styles.infoText}>{findUniqueTour.priceInfo}</Text>
                                </View>
                                <View style={styles.line} />
                                {
                                    peopleRender()
                                }
                                {
                                    includesRender()
                                }
                                {
                                    takesRender()
                                }
                                {
                                    transportationsRender()
                                }
                                {
                                    scheduleRender()
                                }
                                <View style={{ marginVertical: 16, height: 1 }} />
                            </View>
                        </View>
                    </View>
                </Window>
            </ScrollView>
            <Window>
                <View style={styles.footer}>
                    {/* <TouchableOpacity onPress={() => Linking.openURL(`tel:${findUniqueTour.phone}`)} style={styles.button}>
                    <Text style={styles.buttonText}>Позвонить</Text>
                </TouchableOpacity> */}
                    <TouchableOpacity onPress={() => Linking.openURL(`https://wa.me/${findUniqueTour.phone}?text=Здравствуйте! Пишу из сайта DomikiYkt.ru.`)} style={styles.button}>
                        <Text style={styles.whatsAppButtonText}>Связаться с организатором отдыха</Text>
                    </TouchableOpacity>
                </View>
            </Window>
            <FsLightbox
                toggler={toggler}
                sources={images}
                sourceIndex={carouselIndex}
            />
        </>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: windowWidth > 768 ? 16 : 0,
    },
    objectImage: {
        overflow: 'hidden',
    },
    tourInfo: {
        justifyContent: 'space-between',
        position: 'relative',
        borderRadius: 20,
        overflow: 'hidden',
        backgroundColor: '#FFF',
        paddingHorizontal: windowWidth > 768 ? 0 : 16,
        borderRadius: 20,
        overflow: 'hidden',
        left: 0,
        right: 0
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    objectBtnWrap: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 36,
        marginTop: 36,
    },
    back: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        borderColor: '#D1D5DB',
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: windowWidth > 768 ? 0 : 16,
    },
    copyLink: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        borderColor: '#D1D5DB',
        borderWidth: 1,
        backgroundColor: '#FFF',
        margin: windowWidth > 768 ? 0 : 16,
    },
    tagsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 16,
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    countContainer: {
        height: 28,
        paddingHorizontal: 10,
        borderRadius: 6,
        backgroundColor: 'rgba(17, 25, 40, 0.64)',
        backdropFilter: 'blur(8px)',
        justifyContent: 'center',
        alignItems: 'center'
    },
    countText: {
        color: '#fff',
        fontWeight: '500',
    },
    title: {
        fontSize: 24,
        color: '#101828',
        fontWeight: '600',
        marginTop: 16,
        marginBottom: 24
    },
    description: {
        color: '#101828',
        marginVertical: 8
    },
    line: {
        marginVertical: 16,
        height: 1,
        backgroundColor: '#EAECF0'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 16,
        marginBottom: 14
    },
    infoText: {
        color: '#101828',
        fontSize: 16,
        marginLeft: 8
    },
    infoTextGray: {
        color: '#6B7280',
        fontSize: 16,
        marginLeft: 8
    },
    week: {
        fontWeight: '500',
        color: '#101828',
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762'
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    },
    whatsAppButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#25d366'
    },
    whatsAppButtonText: {
        color: '#101828',
        fontSize: 16,
        fontWeight: '500'
    },
    dateGroup: {
        color: '#667085',
        fontSize: 16,
        marginVertical: 8
    },
    clipboard: {
        color: '#101828',
        fontSize: 16,
        marginBottom: 8
    },
    scroll: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 18,
        backgroundColor: '#EAECF0',
        position: 'absolute',
    },
    firstObjectImage: {
        width: '50%',
        aspectRatio: 1/1,
    },
    otherObjectImage: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '50%',
        gap: 8,
    },
    desktopImageWrap: {
        flexDirection: 'row',
        width: '100%',
        borderRadius: 16,
        overflow: 'hidden',
        gap: 8,
    },
    objectImagesPaginationWrap: {
        flexDirection: 'row',
        position: 'absolute',
        columnGap: 3,
        width: '100%',
        justifyContent: 'center',
        bottom: 36
    },
    objectImagesPagination: {
        width: 6,
        height: 6,
        backgroundColor: '#D1D5DB',
        borderRadius: 6,
    },
    objectImagesPaginationActive: {
        backgroundColor: '#fff',
    },
})

export default TourScreen