import React from 'react'
import { ScrollView, ImageBackground, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'

import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_MANY_OBJECT } from '../gqls'

import { imageUrl } from '../utils/const'

import { useCompany } from '../hooks/company'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const MyObjectsScreen = () => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()

    const [findManyObjectQuery, { data: findManyObjectData, loading: findManyObjectLoading, error: findManyObjectError }] = useLazyQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only',
    })

    useCompany({
        onCompleted: async ({ findCurrentCompany }) => {
            if (findCurrentCompany != null)
                await findManyObjectQuery({
                    variables: {
                        orderBy: {
                            createdAt: 'desc'
                        },
                        where: {
                            companyId: {
                                equals: findCurrentCompany.id
                            }
                        }
                    }
                })
            else
                window.location.replace('/profile');
        },
        onError: () => {
            window.location.replace('/profile');
        }
    })

    const squareSize = width > 1024 ? 484 : width - 32

    if (findManyObjectLoading || !findManyObjectData) return <Loading />
    if (findManyObjectError) return <Error />

    const findManyObject = findManyObjectData?.findManyObject || []

    const getMinPrice = (data) => {
        const prices = data.filter(object => object.status == true).map(object => parseInt(object.price))
        return Math.min(...prices)
    }

    const getMinDiscount = (data) => {
        const discounts = data.filter(object => object.discount && object.status == true).map(object => parseInt(object.discount))
        if (!discounts || discounts.length === 0) return null
        return Math.min(...discounts)
    }

    const getRating = (requests) => {
        if (requests.length === 0) return (0).toFixed(2)
        const rating = requests.map(object => object.rating).reduce((a = 0, b = 0) => a + b, 0) / requests.length
        if (rating === 0) return (0).toFixed(2)
        return rating.toFixed(2)
    }

    const getTags = (object) => {
        if (object.premium) {
            return (
                <View style={[styles.tagHot, { backgroundColor: '#BCC8B2' }]}>
                    <Text style={styles.hotText}>Интересно</Text>
                </View>
            )
        }
        if (object.hot) {
            return (
                <>
                    <View style={styles.tagHot}>
                        <Text style={styles.hotText}>Горячие</Text>
                    </View>
                    <View style={styles.tagDeadline}>
                        <MaterialCommunityIcons name='history' size={20} color='#000' />
                        <Text style={styles.deadlineText}>осталось 3 дня</Text>
                    </View>
                </>
            )
        }
        return null
    }

    const getPeople = (object) => {
        if (!object.people) return null
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}>
                <Ionicons style={{ marginTop: 1.5 }} name='ios-people-outline' size={23} color='#000' />
                <Text style={{ color: '#000', marginLeft: 4, fontSize: 16 }}>{object.people}</Text>
            </View>
        )
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                <Window>
                    <View style={styles.objectsContainer}>
                        {
                            [...findManyObject].map((object, index) => (
                                <TouchableOpacity key={index} onPress={() => navigation.navigate('MyObject', { id: object.id })}>
                                    <ImageBackground style={[styles.objectImage, { height: squareSize, width: squareSize }]} source={object.images_720.length > 0 ? { uri: imageUrl(object.images_720[0]) } : { uri: imageUrl(object.images[0]) }}>
                                        <View style={styles.tagsContainer}>
                                            {
                                                getTags(object)
                                            }
                                        </View>
                                    </ImageBackground>
                                    <View style={[styles.infoContainer, { justifyContent: 'space-between', marginTop: 8 }]}>
                                        <Text style={[styles.infoTitle, { maxWidth: squareSize - 114 }]}>{object.name}</Text>
                                        <View style={[styles.infoGroup, { maxWidth: 114 }]}>
                                            {
                                                getPeople(object)
                                            }
                                            <Ionicons name='ios-star' size={20} color='#000' />
                                            <Text style={styles.rate}>{getRating(object.requests.filter(object => object.rating > 0 && dayjs(object.reservedAt).diff(dayjs(), 'day') <= -3))}</Text>
                                        </View>
                                    </View>
                                    {
                                        getMinDiscount(object.schedules) && getMinPrice(object.schedules) > getMinDiscount(object.schedules) ? (
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={styles.price}>от </Text>
                                                <Text style={[styles.price, { textDecorationLine: 'line-through', color: '#667085', fontWeight: '400' }]}>{getMinPrice(object.schedules)} ₽</Text>
                                                <Text style={styles.price}> {getMinDiscount(object.schedules)} ₽</Text>
                                            </View>
                                        ) : (
                                            <Text style={styles.price}>от {getMinPrice(object.schedules)} ₽</Text>
                                        )
                                    }
                                </TouchableOpacity>
                            ))
                        }
                    </View>
                </Window>
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    objectsContainer: {
        gap: 24,
        marginHorizontal: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 24,
        minHeight: '100%'
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    infoGroup: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoTitle: {
        fontSize: 20,
        fontWeight: '500',
        color: '#101828',
    },
    infoPeopleCount: {
        fontSize: 16,
        fontWeight: '400',
        color: '#101828',
        marginLeft: 2,
    },
    rate: {
        fontSize: 16,
        color: '#101828',
        marginLeft: 4
    },
    price: {
        color: '#101828',
        fontSize: 16,
        fontWeight: '500',
        marginTop: 9
    },
    tagsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 16
    },
    tagHot: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#E6B762',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotText: {
        color: '#FCFCFD',
        fontSize: 16,
        fontWeight: 'bold'
    },
    tagDeadline: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#EAECF0',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deadlineText: {
        color: '#000',
        fontSize: 14,
        fontWeight: '400',
        marginLeft: 4
    }
})

export default MyObjectsScreen