import React, { useState } from 'react'
import { ScrollView, Image, StyleSheet, Text, TouchableOpacity, View, Linking, PanResponder, Dimensions, Modal } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { toast } from 'react-toastify';
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useUser } from '../hooks/user'

import { FIND_UNIQUE_REQUEST_FOR_RESERVATION, UPDATE_ONE_REQUEST, PAYMENT_COMPANY } from '../gqls'

import { imageUrl, logoBase64 } from '../utils/const'

import Loading from '../components/Loading'
import Window from '../components/Window'
import QRCode from 'react-native-qrcode-svg';

dayjs.locale('ru')

const statuses = {
    '0': 'Ждет подтверждения',
    '1': 'Оплачен',
    '3': 'Отменен',
    '4': 'Отменен'
}

// Бронирование и оплата внизу после обьекта

const ReservationScreen = ({ route }) => {
    const { id } = route.params
    const windowWidth = Dimensions.get('window').width;
    const isMobile = windowWidth <= 1024;
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(false)
    const [_statusChangeHistory, _setStatusChangeHistory] = useState([])
    const [visibleModalMobile, setVisibleModalMobile] = useState(false);
    const [visibleModalDesktop, setVisibleModalDesktop] = useState(false);
    const [offset, setOffset] = useState(0);
    const [textQRcode, setTextQRcode] = useState('');

    const [findUniqueRequestQuery, { data: findUniqueRequestData, loading: findUniqueRequestLoading, error: findUniqueRequestError }] = useLazyQuery(FIND_UNIQUE_REQUEST_FOR_RESERVATION, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findUniqueRequest }) => {
            _setStatusChangeHistory(findUniqueRequest.statusChangeHistory ? findUniqueRequest.statusChangeHistory : [])
        }
    })

    useUser({
        onCompleted: async ({ findCurrentUser }) => {
            await findUniqueRequestQuery({
                variables: {
                    where: {
                        userId: {
                            equals: findCurrentUser.id
                        },
                        id
                    }
                }
            })
        },
        onError: () => {
            window.location.replace('/profile');
        }
    })

    const [paymentCompanyMutation, { data: paymentCompanyData }] = useMutation(PAYMENT_COMPANY, {
        onCompleted: ({ paymentCompany }) => {
            Linking.openURL(paymentCompany)
        }
    })

    const [updateOneRequestMutation] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: () => {
            setIsLoading(false);
            toast('Бронь отменена')
        },
        onError: () => {
            setIsLoading(false);
        }
    })

    if (findUniqueRequestLoading || findUniqueRequestError || !findUniqueRequestData || isLoading) return <Loading />

    const onCancelAlert = () => {
        const title = "Отмена бронирования"
        const description = "Вы действительно хотите отменить бронь?"
        const result = window.confirm([title, description].filter(Boolean).join('\n'))
        if (result)
            onCancel()
    }

    const { findUniqueRequest } = findUniqueRequestData
    if (findUniqueRequest == null) {
        window.location.replace('/profile')
    }
    const remain = dayjs(findUniqueRequest.reservedAt).diff(dayjs(), 'day')
    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: (evt, gestureState) => {
            setOffset(gestureState.dy);
        },
        onPanResponderRelease: (evt, gestureState) => {
            console.log(gestureState.dy)
            // if (gestureState.dy > 0) {
            setVisibleModalMobile(false);
            setVisibleModalDesktop(false);
            // }
        },
    });
    const onPayment = async () => {
        const legal = findUniqueRequest?.object?.company?.legal
        if (legal) {
            const companyBilling = findUniqueRequest?.object?.company?.CompanyBilling
            if (companyBilling.length > 0) {
                const companyBillingInfo = companyBilling[0];
                setTextQRcode(`ST00012|Name=${companyBillingInfo.fullName}|PersonalAcc=${companyBillingInfo.account}|BankName=${companyBillingInfo.bankName}|BIC=${companyBillingInfo.bik}|CorrespAcc=${companyBillingInfo.correspAcc}|Sum=${findUniqueRequest.reserve}00|SumRub=${findUniqueRequest.reserve}|Purpose=Предоплата за бронирование ${findUniqueRequest?.object?.name}|PayeeINN=${companyBillingInfo.inn}`);
                if (isMobile)
                    setVisibleModalMobile(true)
                else
                    setVisibleModalDesktop(true)
            }
            // 
            // if (paymentCompanyData && paymentCompanyData.paymentCompany) {
            //     const paymentCompany = paymentCompanyData.paymentCompany
            //     Linking.openURL(paymentCompany)
            // } else {
            //     let amount = findUniqueRequest.discount || findUniqueRequest.price
            //     if (findUniqueRequest?.schedule?.cancel) {
            //         amount += findUniqueRequest.schedule.cancel
            //     }
            //     await paymentCompanyMutation({
            //         variables: {
            //             data: {
            //                 companyId: findUniqueRequest.object.company.id,
            //                 requestId: findUniqueRequest.id,
            //                 amount: amount.toString()
            //             }
            //         }
            //     })
            // }
        } else {
            if (findUniqueRequest.object.company.paymentType == 0) {
                const phone = findUniqueRequest.object.company.payment
                navigator.clipboard.writeText(phone)
                toast('Номер скопирован, оплатите по номеру телефона')
            } else {
                const paymentCardNumber = findUniqueRequest.object.company.paymentCardNumber
                navigator.clipboard.writeText(paymentCardNumber)
                toast('Номер карты скопирован, оплатите по номеру банковской карты')
            }
        }
    }

    function dayTitle(number) {
        if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return number + ' дней';
        var last_num = number % 10;
        if (last_num == 1) return number + ' день';
        if ([2, 3, 4].includes(last_num)) return number + ' дня';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return number + ' дней';
    }

    const onCancel = async () => {
        setIsLoading(true)
        var tempData = [];
        _statusChangeHistory.forEach(element => {
            tempData.push(element)
        });
        dayjs.extend(utc);
        dayjs.extend(tz);
        tempData.push(JSON.stringify({
            'date': dayjs().tz("Asia/Yakutsk").format('YYYY-MM-DD HH:mm:ss'),
            'status': 4,
            'who': 'user_web'
        }))
        _setStatusChangeHistory(tempData)
        await updateOneRequestMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    status: {
                        set: '4'
                    },
                    statusChangeHistory: tempData,
                }
            },
        })
    }

    const onReview = async () => {
        navigation.navigate('Review', { id: findUniqueRequest.id })
    }

    const footerRender = () => {
        const status = findUniqueRequest.status
        const cancel = findUniqueRequest.cancel
        if (status === '0') {
            return (
                <View style={styles.footer}>
                    {
                        findUniqueRequest?.object?.company?.legal == true ? null :
                            findUniqueRequest?.object?.company?.comment ? (
                                <View>
                                    <Text style={[styles.total, { fontSize: 14, fontWeight: '500', marginBottom: 14 }]}>{findUniqueRequest.object.company.comment}</Text>
                                </View>
                            ) : null
                    }
                    <TouchableOpacity onPress={onCancelAlert} style={styles.button}>
                        <Text style={styles.buttonText}>Отменить</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onPayment} style={[styles.button, { backgroundColor: '#BCC8B2', marginTop: 12 }]}>
                        <Text style={styles.buttonText}>Оплатить{findUniqueRequest?.object?.company?.legal === false ? (findUniqueRequest?.object?.company?.paymentType == 0 ? ` по номеру телефона +${findUniqueRequest?.object?.company?.payment}` : ` по номеру банковской карты ${findUniqueRequest?.object?.company?.paymentCardNumber}`) : ' по QR коду'}</Text>
                    </TouchableOpacity>
                </View>
            )
        }
        if ((!cancel && status === '1' && !findUniqueRequest.paymentId && remain >= 0) || (status === '1' && cancel && remain >= 2)) {
            return (
                <View style={styles.footer}>
                    <TouchableOpacity onPress={onCancelAlert} style={styles.button}>
                        <Text style={styles.buttonText}>Отменить</Text>
                    </TouchableOpacity>
                    {remain == 0 ? <TouchableOpacity onPress={onReview} style={[styles.button, { backgroundColor: '#BCC8B2', marginTop: 12 }]}>
                        <Text style={styles.buttonText}>{findUniqueRequest.ratingAt !== null ? 'Изменить отзыв' : 'Оставить отзыв'}</Text>
                    </TouchableOpacity> : null}
                </View>
            )
        }
        if (remain <= -1 && (status !== '3' || status !== '4')) {
            if (findUniqueRequest.ratingAt !== null) {
                const ratingAtRemain = dayjs(findUniqueRequest.ratingAt).diff(dayjs(), 'day')
                const day = 3 - (ratingAtRemain * -1)
                if (ratingAtRemain >= -3) {
                    return (
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={onReview} style={styles.button}>
                                <Text style={styles.buttonText}>Изменить отзыв (осталось {dayTitle(day != 0 ? day : 1)})</Text>
                            </TouchableOpacity>
                        </View>
                    )
                }
            } else
                return (
                    <View style={styles.footer}>
                        <TouchableOpacity onPress={onReview} style={styles.button}>
                            <Text style={styles.buttonText}>Оставить отзыв</Text>
                        </TouchableOpacity>
                    </View>
                )
        }
        return null
    }

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <View style={styles.request} key={findUniqueRequest.id}>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Дата оформления</Text>
                            <Text>{dayjs(findUniqueRequest.createdAt).format('DD.MM.YY')}</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Дата и время брони</Text>
                            <Text>{dayjs(findUniqueRequest.reservedAt).format('DD.MM.YY')} {findUniqueRequest.schedule != null ? findUniqueRequest.schedule.startAt + "-" + findUniqueRequest.schedule.endAt : ""}</Text>
                        </View>
                        {
                            findUniqueRequest.cancel ? (
                                <View style={styles.group}>
                                    <Text style={styles.requestTitle}>Отмена брони до</Text>
                                    <Text>{dayjs(findUniqueRequest.reservedAt).subtract(3, 'day').format('DD.MM.YYYY')}</Text>
                                </View>
                            ) : null
                        }
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Стоимость брони</Text>
                            <Text>{(findUniqueRequest?.reserve || findUniqueRequest?.schedule?.reserve) || '-'} ₽</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Полная стоимость</Text>
                            <Text>{(findUniqueRequest?.price || findUniqueRequest?.schedule?.price) + (findUniqueRequest?.schedule?.cancel || 0) || '-'} ₽</Text>
                        </View>
                        <View style={styles.group}>
                            <Text style={styles.requestTitle}>Наименование арендодателя</Text>
                            <Text>{findUniqueRequest.object.company.name}</Text>
                        </View>
                        <View activeOpacity={1} style={styles.group}>
                            <Text style={styles.requestTitle}>Телефон арендодателя</Text>
                            <Text>+{findUniqueRequest.object.company.contact}</Text>
                        </View>
                        <View activeOpacity={1} style={styles.group}>
                            <Text style={styles.requestTitle}>Статус</Text>
                            <Text>{statuses[findUniqueRequest.status]}</Text>
                        </View>
                        {
                            findUniqueRequest.rating > 0 ? (
                                <>
                                    <View style={styles.group}>
                                        <Text style={styles.requestTitle}>Моя оценка</Text>
                                        <View style={styles.rateContainer}>
                                            {
                                                [1, 2, 3, 4, 5].map((object, index) => (
                                                    <View key={index}>
                                                        <Ionicons name='ios-star' size={28} color={findUniqueRequest.rating >= object ? '#E6B762' : '#D0D5DD'} />
                                                    </View>
                                                ))
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.group}>
                                        <Text style={styles.requestTitle}>Мой отзыв</Text>
                                        <Text>{findUniqueRequest.review}</Text>
                                    </View>
                                </>
                            ) : null
                        }
                        <TouchableOpacity onPress={() => navigation.navigate('Object', { id: findUniqueRequest.object.id })} style={styles.objectContainer}>
                            <Image source={findUniqueRequest.object.images_720.length > 0 ? { uri: imageUrl(findUniqueRequest.object.images_720[0]) } : { uri: imageUrl(findUniqueRequest.object.images[0]) }} style={styles.image} />
                            <Text style={styles.objectName}>{findUniqueRequest.object.name}</Text>
                        </TouchableOpacity>
                        <View style={{ marginTop: 12 }}>
                            <div dangerouslySetInnerHTML={{ __html: findUniqueRequest.object.booking }} />
                        </View>
                        <View style={{ marginTop: 24 }}>
                            <div dangerouslySetInnerHTML={{ __html: findUniqueRequest.object.rules }} />
                        </View>
                        <Modal
                            visible={visibleModalDesktop}
                            animationType="fade"
                            transparent={true}
                        >
                            <ScrollView>
                                <View style={styles.centeredView}>
                                    <View style={styles.modalView}>
                                        <View style={styles.modalComfortsClose}>
                                            <Text style={styles.titleText}>Отсканируйте QR код</Text>
                                            <TouchableOpacity style={styles.modalComfortsCloseBtn} onPress={() => setVisibleModalDesktop(false)}>
                                                {windowWidth > 768 ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                        <path d="M8 8L16 16M16 16L24 24M16 16L24 8M16 16L8 24" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M12.4999 15L7.49994 10L12.4999 5" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                )}
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <QRCode
                                                value={`${textQRcode}`}
                                                logo={{ uri: logoBase64 }}
                                                logoSize={60}
                                                size={300}
                                                logoBackgroundColor='transparent'
                                            />
                                        </View>
                                    </View>
                                </View>
                            </ScrollView>
                        </Modal>
                        <Modal
                            visible={visibleModalMobile}
                            transparent={true}
                            onRequestClose={() => setVisibleModalMobile(false)}
                        >
                            <View style={styles.overlay} />
                            <View
                                style={[styles.modalContent, { transform: [{ translateY: offset }] }]}
                                {...panResponder.panHandlers}
                            >
                                <View style={styles.closeLine} />
                                <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Text style={styles.titleText}>Отсканируйте QR код</Text>
                                    <Text style={styles.descriptionText}>Сохраните QR-код: сделайте скриншот экрана с отображенным QR-кодом и оплатите в приложении вашего банка</Text>
                                </View>
                                <View style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 20, marginBottom: 20
                                }}>
                                    <QRCode 
                                        value={`${textQRcode}`}
                                        logo={{ uri: logoBase64 }}
                                        logoSize={60}
                                        size={300}
                                        logoBackgroundColor='transparent'
                                    />
                                </View>
                            </View>
                        </Modal>
                    </View>
                </Window>
            </ScrollView>
            <Window>
                {
                    footerRender()
                }
            </Window>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    request: {
        marginVertical: 16
    },
    title: {
        color: '#101828',
        fontSize: 17,
        fontWeight: '500',
        marginBottom: 12,
        marginHorizontal: 16
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8
    },
    headerTitle: {
        color: '#101828'
    },
    requestTitle: {
        fontSize: 12,
        marginBottom: 4,
        color: '#667085'
    },
    group: {
        marginBottom: 12
    },
    objectContainer: {
        marginVertical: 12,
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        height: 56,
        width: 56,
        borderRadius: 12,
        backgroundColor: '#D0D5DD',
        marginRight: 24
    },
    objectName: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 16
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    rateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4
    },
    total: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 20
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,
        position: 'relative',
    },
    modalView: {
        width: 400,
        height: 400,
        marginVertical: (Dimensions.get('window').height - 400) / 2,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        padding: 20,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height / 2,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        zIndex: 10,
        padding: 20,
        position: 'absolute',
        bottom: 0,
        left: 0,

    },
    titleText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginRight: 10,
    },
    descriptionText: {
        fontSize: 16,
        textAlign: 'center',
        color: '#6A6A6A',
        marginRight: 10,
        marginTop: 10
    },
    modalComfortsCloseBtn: {
        position: 'absolute',
        top: 5,
        right: 10,
        fontSize: 20,
        fontWeight: 'bold',
    },
    closeLine: {
        width: '40%',
        height: 2,
        alignSelf: 'center',
        backgroundColor: '#000',
        marginBottom: 10,
    },
})

export default ReservationScreen