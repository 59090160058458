import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Linking, ScrollView } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import Footer from '../components/Footer'
import Window from '../components/Window'

const text = `По вопросам размещения объекта размещения, туристического продукта или активного вида отдыха, а также рекламы на сайте — вы можете`
const PartnerScreen = () => {

    const openURL = (url) => Linking.openURL(url)

    return (
        <Window style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ marginTop: 16 }}>
                <View style={styles.container}>
                    <Text style={styles.text}>{text}</Text>
                    <Text style={styles.text}>{`\nПозвонить`}</Text>
                    <Text style={{ color: 'blue', fontSize: 16, }} onPress={() => Linking.openURL('tel:+79142221179')}>+79142221179</Text>
                    <Text style={styles.text}>{`\nНаписать в WhatsApp, Telegram`}</Text>
                    <Text style={{ color: 'blue', fontSize: 16 }} onPress={() => Linking.openURL('t.me/+79142221179')}>+79142221179</Text>
                    <Text style={styles.text}>{`\nНаписать на почту`}</Text>
                    <Text style={{ color: 'blue', fontSize: 16 }} onPress={() => Linking.openURL('mailto:domikiykt@yandex.ru')}>domikiykt@yandex.ru</Text>
                    <TouchableOpacity onPress={() => openURL('https://domikiykt.ru/documents/tariff.pdf')} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 24 }}>
                        <Ionicons name='download-outline' size={26} />
                        <Text style={{ fontSize: 20, marginLeft: 6 }}>Тарифы</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
            <Footer />
        </Window>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16
    },
    text: {
        color: '#000',
        fontSize: 16
    },
})

export default PartnerScreen