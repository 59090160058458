import React from 'react'
import { ScrollView, ImageBackground, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_MANY_REQUEST_FOR_RESERVATION } from '../gqls'

import { imageUrl } from '../utils/const'
import { useUser } from '../hooks/user'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

const ReservationsScreen = () => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()

    const [findManyRequestQuery, { data: findManyRequestData, loading: findManyRequestLoading, error: findManyRequestError }] = useLazyQuery(FIND_MANY_REQUEST_FOR_RESERVATION, {
        fetchPolicy: 'network-only'
    })

    useUser({
        onCompleted: async ({ findCurrentUser }) => {
            await findManyRequestQuery({
                variables: {
                    where: {
                        userId: {
                            equals: findCurrentUser.id
                        },
                        object: {
                            isNot: null
                        }
                    },
                    orderBy: {
                        createdAt: 'desc'
                    },
                }
            })
        },
        onError: () => {
            window.location.replace('/profile');
        }
    })

    const squareSize = width > 1024 ? 484 : width - 32

    if (findManyRequestLoading || !findManyRequestData) return <Loading />
    if (findManyRequestError) return <Error />

    const { findManyRequest } = findManyRequestData

    const getMinPrice = (price) => {
        const prices = price.filter(object => object.status == true).map(object => parseInt(object.price))
        return Math.min(...prices)
    }

    const getRating = (rating) => {
        return rating.toFixed(2)
    }

    const getTags = (object) => {
        if (object.premium) {
            return (
                <View style={[styles.tagHot, { backgroundColor: '#BCC8B2' }]}>
                    <Text style={styles.hotText}>Интересно</Text>
                </View>
            )
        }
        if (object.hot) {
            return (
                <>
                    <View style={styles.tagHot}>
                        <Text style={styles.hotText}>Горячие</Text>
                    </View>
                    <View style={styles.tagDeadline}>
                        <MaterialCommunityIcons name='history' size={20} color='#000' />
                        <Text style={styles.deadlineText}>осталось 3 дня</Text>
                    </View>
                </>
            )
        }
        return null
    }

    const getPeople = (object) => {
        if (!object.people) return null
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}>
                <Ionicons style={{ marginTop: 1.5 }} name='ios-people-outline' size={23} color='#000' />
                <Text style={{ color: '#000', marginLeft: 4, fontSize: 16 }}>{object.people}</Text>
            </View>
        )
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginBottom: 24 }}>
                <Window>
                    <View style={styles.objectsContainer}>
                        {
                            findManyRequest.map((object, index) => (
                                <View key={index}>
                                    <Text style={styles.date}>{dayjs(object.reservedAt).format('DD MMMM YYYY')}</Text>
                                    <Text style={styles.week}>{dayjs(object.reservedAt).format('dddd')}</Text>
                                    <TouchableOpacity onPress={() => navigation.navigate('Reservation', { id: object.id })}>
                                        <ImageBackground style={[styles.objectImage, { height: squareSize, width: squareSize }]} source={object.object.images_720.length > 0 ? { uri: imageUrl(object.object.images_720[0]) } : { uri: imageUrl(object.object.images[0]) }}>
                                            <View style={styles.tagsContainer}>
                                                {
                                                    getTags(object.object)
                                                }
                                            </View>
                                        </ImageBackground>
                                        <View style={[styles.infoContainer, { justifyContent: 'space-between', marginTop: 8 }]}>
                                            <Text style={[styles.infoTitle, { maxWidth: squareSize - 114 }]}>{object.object.name}</Text>
                                            <View style={[styles.infoGroup, { maxWidth: 114 }]}>
                                                {
                                                    getPeople(object.object)
                                                }
                                                {getRating(object.rating) > 0 ?
                                                    <View style={[styles.infoContainer, { justifyContent: 'space-between', alignItems: 'center' }]}>
                                                        <Ionicons name='ios-star' size={20} color='#000' />
                                                        <Text style={styles.rate}>{getRating(object.rating)}</Text>
                                                    </View> : ""}
                                            </View>
                                        </View>
                                        <Text style={styles.price}>от {getMinPrice(object.object.schedules)} ₽</Text>
                                    </TouchableOpacity>
                                </View>
                            ))
                        }
                    </View>
                </Window>
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    objectsContainer: {
        gap: 24,
        marginHorizontal: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 12
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    infoGroup: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoTitle: {
        fontSize: 20,
        fontWeight: '500',
        color: '#101828',
    },
    infoPeopleCount: {
        fontSize: 16,
        fontWeight: '400',
        color: '#101828',
        marginLeft: 2,
    },
    rate: {
        fontSize: 16,
        color: '#101828',
        marginLeft: 4
    },
    price: {
        color: '#101828',
        fontSize: 16,
        fontWeight: '500',
        marginTop: 9
    },
    tagsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 16
    },
    tagHot: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#E6B762',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotText: {
        color: '#FCFCFD',
        fontSize: 16,
        fontWeight: 'bold'
    },
    tagDeadline: {
        height: 28,
        width: 135,
        borderRadius: 6,
        backgroundColor: '#EAECF0',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deadlineText: {
        color: '#000',
        fontSize: 14,
        fontWeight: '400',
        marginLeft: 4
    },
    date: {
        color: '#98A2B3',
        fontSize: 12,
        textTransform: 'uppercase',
        marginBottom: 2
    },
    week: {
        color: '#101828',
        fontSize: 24,
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: 12
    }
})

export default ReservationsScreen