import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { favoritesVar } from '../utils/cache'

const uri = 'https://domikiykt.ru/graphql'

const httpLink = new HttpLink({
    uri
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'Apollo-Require-Preflight': 'true'
        }
    }
})

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.map(async ({ message, locations, path }) => {
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        })
    }
    if (networkError) console.error(`[Network error]: ${networkError}`)
})

const link = ApolloLink.from([errorLink, authLink, httpLink])

const cache = new InMemoryCache({
    typePolicies: {
        Object: {
            fields: {
                favorite: {
                    read: (_, { readField }) => {
                        const id = readField('id')
                        const favorite = favoritesVar().some((object) => object === id)
                        return favorite
                    }
                }
            }
        },
    },
})

const client = new ApolloClient({
    link,
    credentials: 'include',
    fetchOptions: {
        mode: 'no-cors',
    },
    cache
})

export default client