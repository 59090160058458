import React, { useState, useEffect } from 'react'
import { Platform, ScrollView, Image, StyleSheet, Text, TextInput, TouchableOpacity, View, useWindowDimensions, Dimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'

import { useQuery } from '@apollo/client'

import { imageUrl } from '../utils/const'

import { FIND_MANY_TOUR } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Footer from '../components/Footer'
import Window from '../components/Window'

const ToursScreen = (props) => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()

    const squareSize = width > 1024 ? 484 : width - 32

    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)
    const [people, setPeople] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
    const [minPrice, setMinPrice] = useState('');   
    const [maxPrice, setMaxPrice] = useState('');
    const [isSearchListVisible, setIsSearchListVisible] = useState(false);

    useEffect(() => {
        const { params } = props.route
        setFrom(params?.from || undefined)
        setTo(params?.to || undefined)
        setPeople(params?.people || undefined)
    }, [props.route.params])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 300);
    
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    const isMobile = width <= 1024;

    const { data: findManyTourData, loading: findManyTourLoading, error: findManyTourError } = useQuery(FIND_MANY_TOUR, {
        fetchPolicy: 'network-only',
        variables: {
            orderBy: {
                createdAt: 'desc'
            },
            where: {
                publish: {
                    equals: true
                },
                people: people ? {
                    gte: parseInt(people)
                } : undefined,
                price: {
                    gte: from ? parseInt(from) : undefined,
                    lte: to ? parseInt(to) : undefined,
                }
            }
        }
    })

    function openTour(id) {
        if (Platform.OS === 'web') {
            if (isMobile)
                navigation.navigate('Tour', { id: id })
            else
                window.open("/tours/" + id)
        } else
            navigation.navigate('Tour', { id: object.id })
    }

    if (findManyTourLoading) return <Loading />
    if (findManyTourError) return <Error />

    const filterObjects = (objects, query) => {
        if (!query) return objects;
        return objects.filter(object =>
            object.name.toLowerCase().includes(query.toLowerCase())
        );
    };

    const { findManyTour } = findManyTourData
    const filteredTors = filterObjects([...findManyTour], debouncedQuery)

    const filterByPrice = (tours, min, max) => {
        return tours.filter((tour) => {
            const price = tour.price;
            const isAboveMin = min ? price >= parseInt(min) : true;
            const isBelowMax = max ? price <= parseInt(max) : true;
            return isAboveMin && isBelowMax;
        });
    };

    const handleSelectSearchList = (object) => {
        setSearchQuery(object.name)
        setIsSearchListVisible(false);

        const handler = setTimeout(() => {
            setDebouncedQuery(object.name);
        }, 400);
    
        return () => {
            clearTimeout(handler);
        };
    }

    const filteredTorsByPrice = filterByPrice(filteredTors, minPrice, maxPrice);

    return (
        <ScrollView style={styles.container}>
            <Window style={{ backgroundColor: '#fff', zIndex: 10, marginTop: windowWidth > 768 ? 80 : 0 }}>
                {/* <TouchableOpacity onPress={() => navigation.navigate('Filter', { type: 'tours', from, to, people })} style={styles.searchContainer}>
                    <Ionicons style={styles.searchIcon} name='ios-search' size={24} />
                    <View>
                        <Text style={styles.searchTitle}>Найти подходящий тур</Text>
                        <Text style={styles.searchText}>Поиск тура и активного отдыха</Text>
                    </View>
                </TouchableOpacity> */}
                <View style={styles.toursFilterWrap}>
                    <View style={styles.searchWrap}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="27" viewBox="0 0 24 27" fill="none">
                            <path d="M21.3873 21.1126C21.5741 21.3617 21.6675 21.6264 21.6675 21.9066C21.6675 22.2179 21.5508 22.4904 21.3172 22.7239C21.0837 22.9574 20.8113 23.0742 20.4999 23.0742C20.2197 23.0742 19.9551 22.9808 19.706 22.794L15.3159 18.4038C15.0356 18.6218 14.6854 18.832 14.265 19.0343C13.8447 19.2367 13.4244 19.408 13.0041 19.5481C12.5837 19.6882 12.1478 19.7972 11.6964 19.875C11.2449 19.9528 10.8323 19.9918 10.4587 19.9918C8.18583 19.9918 6.25542 19.1978 4.66751 17.6099C3.0796 16.022 2.28564 14.0994 2.28564 11.842C2.28564 9.5847 3.0796 7.66208 4.66751 6.07417C6.25542 4.48626 8.17804 3.6923 10.4354 3.6923C12.6927 3.6923 14.6153 4.48626 16.2032 6.07417C17.7911 7.66208 18.5851 9.59249 18.5851 11.8654C18.5851 12.6126 18.4294 13.4611 18.1181 14.4107C17.8067 15.3603 17.4331 16.1309 16.9972 16.7225L21.3873 21.1126ZM6.32548 7.73214C6.32548 7.73214 6.04137 8.01625 5.47314 8.58447C4.90492 9.1527 4.62081 10.2386 4.62081 11.842C4.62081 13.4455 5.18903 14.8155 6.32548 15.9519C7.46193 17.0884 8.83189 17.6644 10.4354 17.6799C12.0388 17.6955 13.4166 17.1273 14.5686 15.9753C15.7206 14.8233 16.2966 13.4455 16.2966 11.842C16.2966 10.2386 15.7206 8.86859 14.5686 7.73214C13.4166 6.59569 12.0388 6.02747 10.4354 6.02747C8.83189 6.02747 7.46193 6.59569 6.32548 7.73214Z" fill="black"/>
                        </svg>
                        <TextInput 
                            style={styles.searchInput}
                            placeholder="Поиск"
                            value={searchQuery}
                            onChangeText={(text) => setSearchQuery(text)}
                            placeholderTextColor="#6B7280"
                            onFocus={() => {
                                setIsSearchListVisible(true);
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    setIsSearchListVisible(false);    
                                }, 200);
                            }}
                        />
                        {
                            searchQuery && (
                                <TouchableOpacity onPress={() => setSearchQuery('')}>
                                    <Text style={styles.filterInputDelete}>+</Text>
                                </TouchableOpacity>
                            )
                        }
                    </View>
                    <View style={styles.toursPriceFilter}>
                        <TextInput 
                            style={styles.priceInput}
                            placeholder="От"
                            keyboardType='numeric'
                            value={minPrice}
                            onChangeText={(text) => setMinPrice(text)}
                            placeholderTextColor="#6B7280"
                        />
                        {
                            minPrice && (
                                <TouchableOpacity onPress={() => setMinPrice('')}>
                                    <Text style={styles.filterInputDelete}>+</Text>
                                </TouchableOpacity>
                            )
                        }
                        <TextInput 
                            style={styles.priceInput}
                            placeholder="До"
                            keyboardType='numeric'
                            value={maxPrice}
                            onChangeText={(text) => setMaxPrice(text)}
                            placeholderTextColor="#6B7280"
                        />
                        {
                            maxPrice && (
                                <TouchableOpacity onPress={() => setMaxPrice('')}>
                                    <Text style={styles.filterInputDelete}>+</Text>
                                </TouchableOpacity>
                            )
                        }
                        <Text style={{marginLeft: 8}}>₽</Text>
                    </View>
                </View>
                {
                    filteredTorsByPrice.length > 0 && isSearchListVisible && (
                        <View style={{paddingHorizontal: 16, marginHorizontal: 8, zIndex: 100}}>
                            <View style={[styles.searchList, {top: '-32px'}]}>
                                {
                                    filteredTorsByPrice.slice(0, 7).map((object, index) => (
                                        <TouchableOpacity
                                            style={styles.searchListItem}
                                            onPress={() => handleSelectSearchList(object)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.5 9.99997C17.5 5.85934 14.1406 2.49997 10 2.49997C5.85938 2.49997 2.5 5.85934 2.5 9.99997C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 9.99997Z" stroke="#374151" strokeWidth="1.25" strokeMiterlimit="10"/>
                                                <path d="M13.6982 5.89579L9.12007 7.72704C8.80565 7.85278 8.52005 8.04109 8.2806 8.28054C8.04115 8.51999 7.85284 8.80559 7.7271 9.12001L5.89585 13.6981C5.87312 13.7549 5.86756 13.8171 5.87985 13.8771C5.89214 13.937 5.92175 13.992 5.965 14.0352C6.00826 14.0785 6.06325 14.1081 6.12318 14.1204C6.1831 14.1327 6.24531 14.1271 6.3021 14.1044L10.8802 12.2731C11.1947 12.1474 11.4803 11.9591 11.7197 11.7196C11.9591 11.4802 12.1475 11.1946 12.2732 10.8802L14.1044 6.30204C14.1272 6.24525 14.1327 6.18304 14.1205 6.12312C14.1082 6.06319 14.0786 6.0082 14.0353 5.96494C13.992 5.92169 13.937 5.89208 13.8771 5.87979C13.8172 5.8675 13.755 5.87306 13.6982 5.89579ZM10.0001 10.9376C9.81473 10.9376 9.63347 10.8826 9.4793 10.7796C9.32513 10.6766 9.20497 10.5302 9.13401 10.3589C9.06306 10.1875 9.04449 9.99905 9.08066 9.81719C9.11684 9.63533 9.20613 9.46829 9.33724 9.33718C9.46835 9.20606 9.6354 9.11678 9.81725 9.0806C9.99911 9.04443 10.1876 9.06299 10.3589 9.13395C10.5302 9.20491 10.6766 9.32507 10.7797 9.47924C10.8827 9.63341 10.9376 9.81467 10.9376 10.0001C10.9376 10.2487 10.8389 10.4872 10.6631 10.663C10.4872 10.8388 10.2488 10.9376 10.0001 10.9376Z" fill="#374151"/>
                                            </svg>
                                            <Text>{object.name}</Text>
                                        </TouchableOpacity>
                                    ))
                                }
                            </View>
                        </View>
                    )
                }
            </Window>
            <Window>
                {
                    searchQuery.length > 0 &&
                        <Text style={[styles.searchQueryResult, {marginHorizontal: 16}]}>Результаты по запросу «{searchQuery}»</Text>
                }
                <View style={styles.objectsContainer}>
                    {
                        filteredTorsByPrice.length == 0 ? (
                            <View>
                                <Text style={styles.searchQueryResult}>По вашему результату ничего не найдено</Text>
                            </View>
                        ) : (
                            filteredTorsByPrice.map((object, index) => (
                                <TouchableOpacity key={index} onPress={() => openTour(object.id)}>
                                    <Image style={[styles.objectImage, { height: squareSize, width: squareSize }]} source={object.images_720.length != 0 ? { uri: imageUrl(object.images_720[0]) } : { uri: imageUrl(object.images[0]) }} />
                                    <View style={{ marginTop: 8 }}>
                                        <Text style={[styles.title, { maxWidth: squareSize -32 }]}>{object.name}</Text>
                                        <Text style={[styles.company, { maxWidth: squareSize -32 }]}>Организатор: {object.organization}</Text>
                                    </View>
                                    <Text style={styles.price}>от {object.price} ₽/чел.</Text>
                                </TouchableOpacity>
                            ))
                        )
                    }
                </View>
                <Footer />
            </Window>
        </ScrollView>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    searchContainer: {
        flexDirection: 'row',
        height: 56,
        borderRadius: 28,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        alignItems: 'center',
        marginTop: 12,
        marginHorizontal: 16,
        marginBottom: 12
    },
    searchIcon: {
        marginHorizontal: 12
    },
    searchTitle: {
        color: '#101828',
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 2
    },
    searchText: {
        color: '#98A2B3',
        fontSize: 12,
        fontWeight: '400'
    },
    objectsContainer: {
        gap: 24,
        marginHorizontal: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 24,
        minHeight: '100%'
    },
    objectImage: {
        borderRadius: 20,
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 20,
        fontWeight: '500',
        color: '#101828',
        marginRight: 24
    },
    company: {
        fontSize: 16,
        color: '#98A2B3',
        marginTop: 4
    },
    price: {
        color: '#101828',
        fontSize: 16,
        fontWeight: '500',
        marginTop: 8
    },
    toursFilterWrap: {
        flexDirection: windowWidth > 767 ? 'row' : 'column',
        alignItems: 'center',
        marginHorizontal: 16,
        marginTop: 32,
        marginBottom: 50
    },
    toursPriceFilter: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: windowWidth > 767 ? 0 : 8,
        marginTop: windowWidth > 767 ? 0 : 16,
    },
    searchWrap: {
        flexDirection: 'row',
        borderColor: '#D1D5DB',
        borderWidth: 1,
        borderRadius: 100,
        maxWidth: 436,
        width: '100%',
        paddingHorizontal: 12,
        paddingVertical: 9,
        alignItems: 'center',
        marginRight: windowWidth > 767 ? 24 : 0,
        flex: 1
    },
    searchInput: {
        marginLeft: 20,
        fontSize: 14,
        lineHeight: '150%',
        fontFamily: 'Inter',
        width: '100%',
    },
    priceInput: {
        marginLeft: windowWidth > 767 ? 6 : 0,
        fontSize: 14,
        lineHeight: '150%',
        fontFamily: 'Inter',
        width: windowWidth > 767 ? 100 : '100%',
        borderColor: '#D1D5DB',
        borderWidth: 1,
        borderRadius: 100,
        paddingHorizontal: 12,
        paddingVertical: 12,
        flex: 1
    },
    searchQueryResult: {
        fontSize: 16,
        lineHeight: '150%',
        fontWeight: 500,
        marginBottom: 26,
    },
    filterInputDelete: {
        transform: [{rotate: '45deg'}],
        position: 'absolute',
        right: '12px',
        fontSize: '16',
        fontWeight: 600,
        color: '#a3a3a3',
        bottom: '-8px'
    },
    searchList: {
        position: 'absolute',
        top: windowWidth > 768 ? 72 : 140,
        backgroundColor: '#fff',
        maxWidth: 436,
        width: '100%',
        left: 0,
        paddingHorizontal: 20,
        paddingVertical: 24,
        borderRadius: 16,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.5,
        shadowRadius: 2.22,

        elevation: 10,
    },
    searchListItem: {
        marginVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
    },
})

export default ToursScreen